export const validateEmail = (email: string) => {
  const emailValidator =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailValidator.test(email.toLowerCase());
};

export const validatePassword = (password: string) => {
  const passwordValidator =
    /^(?=.*[A-Za-z])(?=.*\d)(.*[#$@!%&*?]?)[A-Za-z\d#$@!%&*?]{8,}$/;
  return passwordValidator.test(password);
};

export const validatePhoneNumber = (phone: string) => {
  const phoneValidator = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
  return phoneValidator.test(phone);
};

export const validateColor = (color: string) => {
  const colorValidator = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  return colorValidator.test(color);
};

export const validateNepaliNumber = (phone: string) => {
  const nepaliPhoneNumber = /^[0-9]{10}$/;
  return nepaliPhoneNumber.test(phone);
};

export const validateNcellNumber = (phone: string) => {
  const number = phone.slice(0, 3);
  const ntcNumber = /^(980|981|982)/;
  return ntcNumber.test(number);
};

export const validateNTCNumber = (phone: string) => {
  const number = phone.slice(0, 3);
  const ntcNumber = /^(984|985|986|974|975|972|963)/;
  return ntcNumber.test(number);
};

export const validateSmallNumber = (phone: string) => {
  const number = phone.slice(0, 3);
  const ntcNumber = /^(961|962|988)/;
  return ntcNumber.test(number);
};
