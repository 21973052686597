import { SellerActionType } from "../action-types";
import { SellerType } from "../actions/seller";

let sellerProfile: SellerProfileType = localStorage.getItem(
  "aanbhi_seller_profile"
)
  ? JSON.parse(localStorage.getItem("aanbhi_seller_profile") || "")
  : null;

const initialState: SellerProfileType = {
  area: sellerProfile?.area || "",
  business_owner_name: sellerProfile?.business_owner_name || "",
  city: sellerProfile?.city || "",
  company_name: sellerProfile?.company_name || "",
  description: sellerProfile?.description || "",
  document: sellerProfile?.document || null,
  image: sellerProfile?.image || null,
  id: sellerProfile?.id || "",
  is_verified: sellerProfile?.is_verified || false,
  phone: sellerProfile?.phone || "",
  reg_no: sellerProfile?.reg_no || "",
  user: sellerProfile?.user || "",
};

const sellerReducer = (state = initialState, action: SellerType) => {
  switch (action.type) {
    case SellerActionType.GET_SELLER_PROFILE_SUCCESS:
      localStorage.setItem(
        "aanbhi_seller_profile",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        ...action.payload,
      };
    case SellerActionType.UPDATE_SELLER_PROFILE_SUCCESS:
      const newData = {
        ...state,
        ...action.payload,
      };
      localStorage.setItem("aanbhi_seller_profile", JSON.stringify(newData));
      return newData;

    case SellerActionType.GET_SELLER_PROFILE_FAILED:
      localStorage.removeItem("aanbhi_seller_profile");
      return {
        ...state,
        area: "",
        business_owner_name: "",
        city: "",
        company_name: "",
        description: "",
        document: null,
        image: null,
        id: "",
        is_verified: false,
        phone: "",
        reg_no: "",
        user: "",
      };

    case SellerActionType.UPDATE_SELLER_PROFILE_FAILED:
    default:
      return state;
  }
};

export default sellerReducer;
