import { Dispatch } from "redux";
import { getToken } from "./helper";
import axios, { AxiosResponse } from "axios";
import { API } from "../../config";
import { SellerActionType } from "../action-types";

type SellerDataType = {
  company_name: string;
  business_owner_name: string;
  reg_no: string;
  city: string;
  area: string;
  description: string;
  phone: string;
  document: any;
  image: any;
};

export const applyForSeller =
  (data: SellerDataType) => async (dispatch: Dispatch) => {
    const form = new FormData();
    form.append("company_name", data.company_name);
    form.append("business_owner_name", data.business_owner_name);
    form.append("reg_no", data.reg_no);
    form.append("city", data.city);
    form.append("area", data.area);
    form.append("description", data.description);
    form.append("phone", data.phone);
    form.append("document", data.document);
    form.append("image", data.image);

    try {
      const response: AxiosResponse = await axios.post(
        `${API}/sellerprofile/create/`,
        form,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      return response;
    } catch (e: any) {
      return e.response;
    }
  };

export const updateSellerData =
  (id: string | number, form: any) => async (dispatch: Dispatch) => {
    try {
      const response: AxiosResponse = await axios.patch(
        `${API}/sellerprofile/update/${id}/`,
        form,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      dispatch({
        type: SellerActionType.UPDATE_SELLER_PROFILE_SUCCESS,
        payload: response?.data,
      });

      return response;
    } catch (e: any) {
      dispatch({
        type: SellerActionType.UPDATE_SELLER_PROFILE_FAILED,
        payload: e.response,
      });
      return e.response;
    }
  };

export const getSellerStats = () => async () => {
  try {
    const res: AxiosResponse = await axios.get(
      `${API}/transaction/salesstats/`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res;
  } catch (error: any) {
    return error.response;
  }
};

export const deleteProduct = (id: string | number) => async () => {
  try {
    const res: AxiosResponse = await axios.delete(
      `${API}/product/delete/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res;
  } catch (error: any) {
    return error.response;
  }
};

export const addProduct = (form: any) => async () => {
  try {
    const res: AxiosResponse = await axios.post(
      `${API}/product/create/`,
      form,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res;
  } catch (error: any) {
    return error.response;
  }
};

export const editProduct = (data: any, id: string | number) => async () => {
  try {
    const res: AxiosResponse = await axios.put(
      `${API}/product/update/${id}/`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res;
  } catch (error: any) {
    return error.response;
  }
};

export const updateProductImage =
  (form: any, id: string | number) => async () => {
    try {
      const res: AxiosResponse = await axios.patch(
        `${API}/product/image/${id}/`,
        form,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return res;
    } catch (error: any) {
      return error.response;
    }
  };

export const addProductImage = (form: any) => async () => {
  try {
    const res: AxiosResponse = await axios.post(`${API}/product/image/`, form, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return res;
  } catch (error: any) {
    return error.response;
  }
};

export const addCategory = (data: any) => async () => {
  try {
    const res: AxiosResponse = await axios.post(
      `${API}/product/category/`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res;
  } catch (error: any) {
    return error.response;
  }
};

export const addBrand = (data: { name: string }) => async () => {
  try {
    const res: AxiosResponse = await axios.post(`${API}/product/brand/`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return res;
  } catch (error: any) {
    return error.response;
  }
};

export const addManufacturer = (data: { name: string }) => async () => {
  try {
    const res: AxiosResponse = await axios.post(
      `${API}/product/manufacturer/`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res;
  } catch (error: any) {
    return error.response;
  }
};

export const addProductSize = (data: { size: string }) => async () => {
  try {
    const res: AxiosResponse = await axios.post(`${API}/product/size/`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return res;
  } catch (error: any) {
    return error.response;
  }
};

export const addProductColor = (data: { color: string }) => async () => {
  try {
    const res: AxiosResponse = await axios.post(`${API}/product/color/`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return res;
  } catch (error: any) {
    return error.response;
  }
};
