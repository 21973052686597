import { SearchActionType } from "../action-types";
import { SearchAction } from "../actions/search";

interface SearchInitialState {
  searchProducts: Product[];
}

const initialState: SearchInitialState = {
  searchProducts: [],
};

const searchReducer = (state = initialState, action: SearchAction) => {
  switch (action.type) {
    case SearchActionType.GET_SEARCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        searchProducts: action.payload,
      };
    case SearchActionType.GET_SEARCH_PRODUCTS_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default searchReducer;
