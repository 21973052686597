import React, { useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import Input from "../../atomic/Input/Input";

interface FormControlProps {
  name: string;
  label: string;
  type?: "text" | "number" | "email" | "password" | "search" | "date";
  value: string | number;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  placeholder?: string;
  error?: string;
}

const FormControl: React.FC<FormControlProps> = ({
  type = "text",
  label,
  name,
  value,
  handleChange,
  error,
  required,
  minLength,
  maxLength,
  placeholder,
}) => {
  const [passwordStatus, setPasswordStatus] = useState(true);
  return (
    <div className="flex flex-col my-3">
      <label htmlFor={name} className="text-base sm:text-lg mb-1">
        {label} {required && <sup>*</sup>}
      </label>
      <div className="relative w-full">
        <Input
          type={
            type === "password" ? (passwordStatus ? "password" : "text") : type
          }
          name={name}
          value={value}
          handleChange={handleChange}
          inputClassName="w-full"
          placeholder={placeholder}
          minLength={minLength}
          maxLength={maxLength}
        />
        {type === "password" && (
          <span
            className="text-sm sm:text-base text-red absolute top-1/3 right-5 cursor-pointer"
            onClick={() => {
              setPasswordStatus(!passwordStatus);
            }}
          >
            {passwordStatus ? <FaRegEyeSlash /> : <FaRegEye />}
          </span>
        )}
      </div>
      {error && (
        <span className="input-error text-red text-sm my-1">{error}</span>
      )}
    </div>
  );
};

export default FormControl;
