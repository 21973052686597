import crypt from "crypto-js";

export const capitalize = (text: string) => {
  let wordsArray = text.toLowerCase().split(" ");
  let capsArray: string[] = [];

  wordsArray.forEach((word) => {
    capsArray.push(word[0].toUpperCase() + word.slice(1));
  });

  return capsArray.join(" ");
};

export const capitalizeWord = (word: string) => {
  return word[0].toUpperCase() + word.slice(1);
};

export const encryptData = (data: string) => {
  return crypt.AES.encrypt(
    JSON.stringify(data),
    "aanbhi_marketplace111"
  ).toString();
};
