import { FavoritesActionType } from "../action-types";
import { FavoritesAction } from "../actions/favorites";

const localStorageData = localStorage.getItem("aanbhi_favorites_products")
  ? JSON.parse(
      localStorage.getItem("aanbhi_favorites_products") ||
        "{favorites: [], totalQuantity: 0}"
    )
  : { favorites: [], totalQuantity: 0 };

interface FavoritesInitialState {
  favorites: Product[];
  totalQuantity: number;
}

const initialState: FavoritesInitialState = {
  favorites: localStorageData ? localStorageData.favorites : [],
  totalQuantity: localStorageData ? localStorageData.totalQuantity : 0,
};

const favoritesReducer = (state = initialState, action: FavoritesAction) => {
  switch (action.type) {
    case FavoritesActionType.ADD_FAVORITE_PRODUCT:
      let newState = {
        favorites: [action.payload, ...state.favorites],
        totalQuantity: ++state.totalQuantity,
      };
      localStorage.setItem(
        "aanbhi_favorites_products",
        JSON.stringify(newState)
      );
      return newState;

    case FavoritesActionType.REMOVE_FAVORITE_PRODUCT:
      let prevFavoritesProducts = [...state.favorites];
      let index = prevFavoritesProducts.findIndex(
        (product: Product) => product.id === action.payload
      );

      prevFavoritesProducts.splice(index, 1);
      let newFavoritesProducts = {
        ...state,
        favorites: prevFavoritesProducts,
        totalQuantity: --state.totalQuantity,
      };
      localStorage.setItem(
        "aanbhi_favorites_products",
        JSON.stringify(newFavoritesProducts)
      );
      return newFavoritesProducts;

    case FavoritesActionType.CLEAR_FAVORITE:
      let clearedState = {
        ...state,
        favorites: [],
        totalQuantity: 0,
      };
      localStorage.removeItem("aanbhi_favorites_products");
      return clearedState;
    default:
      return state;
  }
};

export default favoritesReducer;
