import React, { Suspense, useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router";
import { useActions } from "./hooks/useAction";
import Login from "./pages/Auth/Login";
import { HelmetProvider, Helmet } from "react-helmet-async";
import AanbhiLogoSmall from "./assets/aanbhi-logo-small.png";
import Aanbhi from "./assets/aanbhi.png";
import routes from "./routes";
import { useTypedSelector } from "./hooks/useSelector";
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";
import axios from "axios";
import { API, firebaseConfig, firebasePublicKey } from "./config";
import { getToken as userToken } from "./redux/action-creators/helper";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import "./App.css";

const fapp = initializeApp(firebaseConfig);

// const messaging = getMessaging(fapp)
const messaging = isSupported().then((a) => {
  if (a) {
    return getMessaging(fapp);
  }
  return null;
});

const FallBack = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  });
  return <></>;
};

function App() {
  const { isAuthenticated, profile } = useTypedSelector((state) => state.users);

  const history = useHistory();
  const {
    getAllProductsCategories,
    getAllCartProducts,
    logout,
    getContactNumbers,
    getFooterCategories,
    getLogo,
    getRewardPoints,
  } = useActions();

  useEffect(() => {
    getContactNumbers();
    getAllProductsCategories();
    getFooterCategories();
    getLogo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getCartProducts();
      getRewardPoints();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const getCartProducts = async () => {
    const res: any = await getAllCartProducts();

    if (res?.status === 403) {
      logout();
      history.push("/login");
    }
  };

  const sellerNotification = (profile: any) => {
    messaging.then((messaging) => {
      if (messaging != null) {
        if (profile.is_seller) {
          getToken(messaging, {
            vapidKey: firebasePublicKey,
          })
            .then((currentToken) => {
              if (currentToken) {
                console.log("Fr Token");
                axios
                  .post(
                    `${API}/notification/fcmtoken/`,
                    {
                      token: currentToken,
                    },
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Bearer ${userToken()}`,
                      },
                    }
                  )
                  .then((res) => console.log("Sucess"))
                  .catch((err) => console.log("Err"));
              } else {
                // Show permission request UI
                console.log(
                  "No registration token available. Request permission to generate one."
                );
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token. ", err);
            });

          onMessage(messaging, (payload) => {
            console.log("Message received. ", payload);
          });
        }
      }
    });
  };

  useEffect(() => {
    sellerNotification(profile);
  }, [profile]);

  return (
    <Suspense fallback={<FallBack />}>
      <HelmetProvider>
        <Helmet>
          <link rel="icon" href={AanbhiLogoSmall} />
          <link rel="apple-touch-icon" href={AanbhiLogoSmall} />
          <meta property="og:image" content={Aanbhi} />
          <meta property="twitter:image" content={Aanbhi} />
        </Helmet>

        <Switch>
          {routes.map((route) => (
            <Route
              exact
              path={route.path}
              component={route.component}
              key={route.name}
            />
          ))}
          <Route exact path="/api/user/verify/*" component={Login} />
          <Redirect to="/page-not-found" />
        </Switch>
      </HelmetProvider>
    </Suspense>
  );
}

export default App;
