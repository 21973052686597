import cookie from "js-cookie";

export const setCookie = (key: string, value: any) => {
  if (process.browser) {
    cookie.set(key, value);
  }
};
export const removeCookie = (key: string) => {
  if (process.browser) {
    cookie.remove(key);
  }
};
export const getCookie = (key: string) => {
  if (process.browser) {
    return cookie.get(key);
  }
};

export const getToken = () => {
  if (process.browser) {
    const token = getCookie("token");
    if (token) {
      return token;
    } else {
      return false;
    }
  }
};
