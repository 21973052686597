import { IconProps } from ".";
import clsx from "clsx";

export default function Icon({
  size = -1,
  height = 6,
  width = 6,
  className = "",
  children,
  color = "currentColor",
  viewBox = "0 0 24 24",
  strokeWidth = 0,
  xmlns = "http://www.w3.org/2000/svg",
}: IconProps) {
  height = size !== -1 ? size : height;
  width = size !== -1 ? size : width;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(
        size !== -1 ? `h-${size} w-${size}` : `h-${height} w-${width}`,
        className
      )}
      width={width}
      fill="none"
      viewBox={viewBox}
      stroke={color}
      strokeWidth={strokeWidth}
    >
      {children}
    </svg>
  );
}
