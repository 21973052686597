import { lazy } from "react";

const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/Auth/Login"));
const Register = lazy(() => import("./pages/Auth/Register"));
const About = lazy(() => import("./pages/About"));

const Category = lazy(() => import("./pages/Products/Category"));
const TrendingNow = lazy(() => import("./pages/Products/TrendingNow"));
const Categories = lazy(() => import("./pages/Categories"));
const DirectShopping = lazy(() => import("./pages/Products/DirectShopping"));
const SellerProducts = lazy(() => import("./pages/Products/SellerProducts"));

const MyCart = lazy(() => import("./pages/MyCart"));
const Favorites = lazy(() => import("./pages/Favorites"));
const Payment = lazy(() => import("./pages/Payment"));
const Confirmation = lazy(() => import("./pages/Confirmation"));

const Profile = lazy(() => import("./pages/User/Profile"));
const Address = lazy(() => import("./pages/User/Address"));
const AddAddress = lazy(() => import("./pages/User/AddAddress"));
const History = lazy(() => import("./pages/User/History"));
const EditAddress = lazy(() => import("./pages/User/EditAddress"));
const ChangePassword = lazy(() => import("./pages/User/ChangePassword"));
const ApplySeller = lazy(() => import("./pages/User/ApplySeller"));
const SellerProfile = lazy(() => import("./pages/User/SellerProfile"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const NewPassword = lazy(() => import("./pages/NewPassword"));

const ProductDetail = lazy(() => import("./pages/Products/ProductDetail"));

const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const SendEmail = lazy(() => import("./pages/SendEmail/SendEmail"));

const Dashboard = lazy(() => import("./pages/Dashboard"));
const AddProduct = lazy(() => import("./pages/Dashboard/AddProduct"));
const AddCategory = lazy(() => import("./pages/Dashboard/AddCategory"));
const AddBrand = lazy(() => import("./pages/Dashboard/AddBrand"));
const AddManufacturer = lazy(() => import("./pages/Dashboard/AddManufacturer"));
const AddColor = lazy(() => import("./pages/Dashboard/AddColor"));
const AddSize = lazy(() => import("./pages/Dashboard/AddSize"));
const ProductList = lazy(() => import("./pages/Dashboard/ProductList"));
const EditProduct = lazy(() => import("./pages/Dashboard/EditProduct"));

const Info = lazy(() => import("./pages/Info"));

const Search = lazy(() => import("./pages/Search"));

const routes = [
  {
    name: "Home",
    path: "/",
    component: Home,
  },
  {
    name: "Login",
    path: "/login",
    component: Login,
  },
  {
    name: "Register",
    path: "/register",
    component: Register,
  },
  {
    name: "About",
    path: "/about",
    component: About,
  },
  {
    name: "Category",
    path: "/products/category/:category/:id",
    component: Category,
  },
  {
    name: "TrendingNow",
    path: "/products/Trending Now",
    component: TrendingNow,
  },
  {
    name: "Categories",
    path: "/products/categories",
    component: Categories,
  },
  {
    name: "DirectShopping",
    path: "/products/direct-shopping",
    component: DirectShopping,
  },
  {
    name: "SellerProducts",
    path: "/products/direct-shopping/:name/:id",
    component: SellerProducts,
  },
  {
    name: "Favorites",
    path: "/wishlist",
    component: Favorites,
  },
  {
    name: "Cart",
    path: "/cart",
    component: MyCart,
  },
  {
    name: "Confirmation",
    path: "/confirmation",
    component: Confirmation,
  },
  {
    name: "Payment",
    path: "/payment",
    component: Payment,
  },
  {
    name: "Profile",
    path: "/account/profile",
    component: Profile,
  },
  {
    name: "Address",
    path: "/account/address",
    component: Address,
  },
  {
    name: "Add Address",
    path: "/account/add-address",
    component: AddAddress,
  },
  {
    name: "History",
    path: "/account/history",
    component: History,
  },
  {
    name: "Edit Address",
    path: "/account/edit-address/:id",
    component: EditAddress,
  },
  {
    name: "Change Password",
    path: "/account/change-password",
    component: ChangePassword,
  },
  {
    name: "Apply for Seller",
    path: "/apply-seller",
    component: ApplySeller,
  },
  {
    name: "Seller Profile",
    path: "/account/seller-profile",
    component: SellerProfile,
  },
  {
    name: "404",
    path: "/page-not-found",
    component: PageNotFound,
  },
  {
    name: "Send Email",
    path: "/send-email",
    component: SendEmail,
  },
  {
    name: "Forgot Password",
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    name: "New Password",
    path: "/new-password",
    component: NewPassword,
  },
  {
    name: "Product",
    path: "/products/product/:id",
    component: ProductDetail,
  },
  {
    name: "Dashboard",
    path: "/seller/dashboard",
    component: Dashboard,
  },
  {
    name: "AddProduct",
    path: "/seller/dashboard/product/add",
    component: AddProduct,
  },
  {
    name: "AddCategory",
    path: "/seller/dashboard/category/add",
    component: AddCategory,
  },
  {
    name: "AddBrand",
    path: "/seller/dashboard/brand/add",
    component: AddBrand,
  },
  {
    name: "AddManufacturer",
    path: "/seller/dashboard/manufacturer/add",
    component: AddManufacturer,
  },
  {
    name: "AddColor",
    path: "/seller/dashboard/color/add",
    component: AddColor,
  },
  {
    name: "AddSize",
    path: "/seller/dashboard/size/add",
    component: AddSize,
  },
  {
    name: "EditProduct",
    path: "/seller/dashboard/products/:id/edit",
    component: EditProduct,
  },
  {
    name: "ProductList",
    path: "/seller/dashboard/products",
    component: ProductList,
  },
  {
    name: "Search",
    path: "/search",
    component: Search,
  },

  {
    name: "Info",
    path: "/info/:slug",
    component: Info,
  },
];

export default routes;
