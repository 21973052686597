import React, { useEffect, useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useActions } from "../../../hooks/useAction";
import { useTypedSelector } from "../../../hooks/useSelector";

const CategoriesBar: React.FC = () => {
  const { categories } = useTypedSelector((state) => state.products);
  const { getAllProductsCategories } = useActions();

  const [show, setShow] = useState(false);

  const [num, setNum] = useState(
    window.innerWidth > 1024
      ? 5
      : window.innerWidth > 768
      ? 3
      : window.innerWidth > 648
      ? 2
      : 1
  );

  window.addEventListener("resize", () => {
    if (window.innerWidth > 1024) setNum(5);
    else if (window.innerWidth > 640) setNum(3);
    else if (window.innerWidth > 400) setNum(2);
    else setNum(1);
  });

  useEffect(() => {
    getAllProductsCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="hidden md:block text-base text-gray-800 bg-yellow">
      <div className="relative flex justify-around max-w-6xl mx-auto">
        {show && (
          <div
            className="duration-200 absolute top-12 left-5 rounded bg-white shadow-xl drop-shadow-md overflow-y-auto px-3 py-5 mx-5 z-30 min-w-max border"
            style={{ maxWidth: "320px", minWidth: "240px", maxHeight: "70vw" }}
          >
            <ul className="w-full">
              {categories &&
                categories.length > 0 &&
                categories.map((category: ProductCategory) => {
                  return (
                    <li
                      key={category.id}
                      className="pr-5 py-2 break-words hover:text-red flex items-center"
                      style={{ minWidth: 100 }}
                    >
                      <Link
                        to={`/products/category/${category.name}/${category.id}`}
                      >
                        {category.name}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}

        {categories && categories.length > 5 && (
          <button
            className="m-2 text-red font-semibold flex items-center"
            onClick={() => setShow(!show)}
          >
            {show ? <FiChevronUp /> : <FiChevronDown />}
            <span className="ml-1">All</span>
          </button>
        )}

        {categories &&
          categories.length > 0 &&
          categories.slice(0, num).map((category: any) => {
            return (
              <div key={category.id} className="m-2 text-base">
                <Link to={`/products/category/${category.name}/${category.id}`}>
                  {category.name}
                </Link>
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default CategoriesBar;
