import { UserAddressActionType } from "../action-types";
import { UserAddressAction } from "../actions/userAddress";

const initialState: { address: UserAddressType[] } = {
  address: [],
};

const userAddressReducer = (
  state = initialState,
  action: UserAddressAction
) => {
  switch (action.type) {
    case UserAddressActionType.GET_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        address: action.payload,
      };
    case UserAddressActionType.GET_USER_ADDRESS_FAILED:
      return {
        ...state,
        address: [],
      };
    case UserAddressActionType.DELETE_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        address: state.address.filter((addr) => addr.id !== action.payload),
      };
    case UserAddressActionType.EDIT_USER_ADDRESS_SUCCESS:
      let newAddress = [...state.address];
      const index = newAddress.findIndex((addr) => addr === action.payload?.id);
      newAddress[index] = action.payload?.address;
      return {
        ...state,
        address: newAddress,
      };

    case UserAddressActionType.EDIT_USER_ADDRESS_FAILED:
    case UserAddressActionType.DELETE_USER_ADDRESS_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default userAddressReducer;
