import { Dispatch } from "redux";
import { SellerActionType, UserActionType } from "../action-types/index";
import { getToken, removeCookie, setCookie } from "./helper";
import axios, { AxiosResponse } from "axios";
import { API } from "../../config";

export const login =
  (data: { email: string; password: string }) => async (dispatch: Dispatch) => {
    try {
      const response = await axios.post(`${API}/user/login/`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      dispatch({ type: UserActionType.USER_LOGIN_SUCCESS });
      setCookie("token", response.data.access_token);

      const res: AxiosResponse = await getCurrentUser();
      if (res.data) {
        dispatch({
          type: UserActionType.GET_USER_PROFILE_SUCCESS,
          payload: res.data,
        });
      }

      if (res?.data?.is_seller) {
        getSellerProfile();
      }
      getRewardPoints();
      return res;
    } catch (e: any) {
      dispatch({ type: UserActionType.USER_LOGIN_FAILED });
      dispatch({ type: UserActionType.GET_USER_PROFILE_FAILED });
      dispatch({ type: SellerActionType.GET_SELLER_PROFILE_FAILED });
      return e.response;
    }
  };

export const register =
  (data: {
    full_name: string;
    phone: string;
    email: string;
    password: string;
    referralcode?: string;
  }) =>
  async (dispatch: Dispatch) => {
    try {
      const res = await axios.post(`${API}/user/register/`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      dispatch({
        type: UserActionType.USER_REGISTER_SUCCESS,
      });
      return res;
    } catch (e: any) {
      dispatch({ type: UserActionType.USER_REGISTER_FAILED });
      return e.response;
    }
  };

export const getCurrentUser = async () => {
  try {
    const res = await axios.get(`${API}/user/current-user/`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return res;
  } catch (e: any) {
    return e.response;
  }
};

export const getSellerProfile = () => async (dispatch: Dispatch) => {
  try {
    const res = await axios.get(`${API}/sellerprofile/me/`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    dispatch({
      type: SellerActionType.GET_SELLER_PROFILE_SUCCESS,
      payload: res.data,
    });
    return res;
  } catch (error: any) {
    dispatch({
      type: SellerActionType.GET_SELLER_PROFILE_FAILED,
    });
    return error.response;
  }
};

export const logout = () => (dispatch: Dispatch) => {
  removeCookie("token");
  dispatch({
    type: UserActionType.USER_LOGOUT,
  });
};

export const forgotPassword = (data: { email: string }) => async () => {
  try {
    const response = await axios.post(`${API}/user/forget-password/`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response;
  } catch (e: any) {
    return e.response;
  }
};

export const changePassword =
  (data: {
    password: string;
    new_password: string;
    confirm_password: string;
  }) =>
  async () => {
    try {
      const response = await axios.post(`${API}/user/change-password/`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });

      return response;
    } catch (e: any) {
      return e.response;
    }
  };

export const newPassword =
  (data: { email: string; verification_code: string; password: string }) =>
  async () => {
    try {
      const response = await axios.patch(`${API}/user/forget-password/`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      return response;
    } catch (e: any) {
      return e.response;
    }
  };

export const subscribe = (email: { email: string }) => async () => {
  try {
    const response = await axios.post(`${API}/user/email-subscribe/`, email, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response;
  } catch (e: any) {
    return e.response;
  }
};

export const updateUser = (form: any) => async (dispatch: Dispatch) => {
  try {
    const res = await axios.patch(`${API}/user/current-user/`, form, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    dispatch({
      type: UserActionType.UPDATE_USER_PROFILE_SUCCESS,
      payload: res.data,
    });
    return res;
  } catch (e: any) {
    return e.response;
  }
};

export const getRewardPoints = () => async (dispatch: Dispatch) => {
  try {
    const res: AxiosResponse = await axios.get(`${API}/user/rewardpoint/`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });

    dispatch({
      type: UserActionType.GET_REWARD_POINTS_SUCCESS,
      payload: res.data,
    });
    return res;
  } catch (error: any) {
    dispatch({ type: UserActionType.GET_REWARDS_POINTS_FAILED });
    logout();
    return error.response;
  }
};
