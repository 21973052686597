import React from "react";
import { BoxCheckbox, DeliveryTruck, Secure, Swap } from "../../atomic/Icon";

const Features = () => {
  return (
    <section className="bg-white text-center">
      <div className="flex flex-wrap justify-between items-center max-w-6xl mx-auto">
        <div className="text-gray-700 w-full sm:w-1/2 lg:w-1/4 flex justify-center flex-col items-center p-5">
          <DeliveryTruck size={45} viewBox="0 0 45 45" />
          <h3 className="text-xl">Express Delivery</h3>
          <p className="text-gray-400 text-base">
            Express Delivery within Yokohama
          </p>
        </div>
        <hr />
        <div className="text-gray-700 w-full sm:w-1/2 lg:w-1/4 flex justify-center flex-col items-center py-10 p-5">
          <Secure size={45} viewBox="0 0 45 45" />
          <h3 className="text-xl">Secure Shopping</h3>
          <p className="text-gray-400 text-base">
            We are commited to protecting the Security of your information
          </p>
        </div>
        <hr />
        <div className="text-gray-700 w-full sm:w-1/2 lg:w-1/4 flex justify-center flex-col items-center py-10 p-5">
          <Swap size={45} viewBox="0 0 45 45" />
          <h3 className="text-xl">Easy Returns</h3>
          <p className="text-gray-400 text-base">
            Returns of Products are free and easy
          </p>
        </div>
        <hr />
        <div className="text-gray-700 w-full sm:w-1/2 lg:w-1/4 flex justify-center flex-col items-center py-10 p-5">
          <BoxCheckbox size={45} viewBox="0 0 45 45" />
          <h3 className="text-xl">Quality Checked</h3>
          <p className="text-gray-400 text-base">
            We are providing top quality products and service
          </p>
        </div>
      </div>
    </section>
  );
};

export default Features;
