import React from "react";
import Icon from "./Icon";

export interface IconProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  size?: number;
  height?: number;
  width?: number;
  color?: string;
  viewBox?: string;
  strokeWidth?: number;
  fill?: string;
  xmlns?: string;
}

export const DeliveryTruck: React.FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M1.40625 14.0625H19.6875L21.0937 16.875H2.8125L1.40625 14.0625ZM3.28125 19.6875H21.5625L22.9687 22.5H4.6875L3.28125 19.6875ZM33.75 34.6875C35.3062 34.6875 36.5625 33.4312 36.5625 31.875C36.5625 30.3187 35.3062 29.0625 33.75 29.0625C32.1937 29.0625 30.9375 30.3187 30.9375 31.875C30.9375 33.4312 32.1937 34.6875 33.75 34.6875ZM36.5625 17.8125H31.875V22.5H40.2375L36.5625 17.8125ZM15 34.6875C16.5562 34.6875 17.8125 33.4312 17.8125 31.875C17.8125 30.3187 16.5562 29.0625 15 29.0625C13.4437 29.0625 12.1875 30.3187 12.1875 31.875C12.1875 33.4312 13.4437 34.6875 15 34.6875ZM37.5 15L43.125 22.5V31.875H39.375C39.375 34.9875 36.8625 37.5 33.75 37.5C30.6375 37.5 28.125 34.9875 28.125 31.875H20.625C20.625 34.9875 18.0937 37.5 15 37.5C11.8875 37.5 9.375 34.9875 9.375 31.875H5.625V25.3125H9.375V28.125H10.8C11.8313 26.9812 13.3312 26.25 15 26.25C16.6687 26.25 18.1687 26.9812 19.2 28.125H28.125V11.25H5.625C5.625 9.16875 7.29375 7.5 9.375 7.5H31.875V15H37.5Z"
        fill="#5E5E5E"
      />
    </Icon>
  );
};

export const Secure: React.FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M21.5398 40.7886C22.091 41.083 22.7829 41.0811 23.3342 40.7867C38.3567 32.7167 39.4235 16.9498 39.4104 12.4667C39.409 12.1064 39.3025 11.7543 39.104 11.4536C38.9056 11.1529 38.6237 10.9165 38.2929 10.7736L23.1992 4.08733C22.959 3.98145 22.6993 3.92692 22.4368 3.92725C22.1743 3.92757 21.9147 3.98274 21.6748 4.08921L6.68604 10.7755C6.36016 10.9185 6.08227 11.1522 5.88547 11.4487C5.68867 11.7453 5.58126 12.0921 5.57604 12.448C5.51229 16.9086 6.41042 32.7092 21.5398 40.7886ZM16.2635 19.2992L20.5629 23.5986L28.6123 15.5492L31.2635 18.2005L20.5629 28.9011L13.6123 21.9505L16.2635 19.2992Z"
        fill="#5E5E5E"
      />
    </Icon>
  );
};

export const Swap: React.FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M31.5 11.25H9V6.75L0 14.625L9 22.5V18H31.5V11.25ZM45 30.375L36 22.5V27H13.5V33.75H36V38.25L45 30.375Z"
        fill="#5E5E5E"
      />
    </Icon>
  );
};

export const BoxCheckbox: React.FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M31.875 39.375L26.7188 33.75L28.8937 31.575L31.875 34.5563L38.6063 27.825L40.7812 30.4688L31.875 39.375ZM24 39.375H9.375C7.29375 39.375 5.625 37.7062 5.625 35.625V9.375C5.625 7.29375 7.29375 5.625 9.375 5.625H35.625C37.7062 5.625 39.375 7.29375 39.375 9.375V24C38.2313 23.3438 36.975 22.875 35.625 22.65V9.375H9.375V35.625H22.65C22.875 36.975 23.3438 38.2313 24 39.375ZM22.5 31.875H13.125V28.125H22.5V31.875ZM27.525 24.375H13.125V20.625H31.875V22.65C30.2812 22.9125 28.8188 23.5125 27.525 24.375ZM31.875 16.875H13.125V13.125H31.875"
        fill="#5E5E5E"
      />
    </Icon>
  );
};

export const Settings: React.FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <svg
        width="260"
        height="260"
        viewBox="0 0 260 260"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M149.447 180.464C148.785 184.519 147.733 188.435 146.315 192.181L159.035 203.706C157.251 207.46 155.22 211.067 152.876 214.455L136.545 209.19C133.969 212.339 131.085 215.219 127.936 217.795L133.201 234.126C129.813 236.47 126.206 238.497 122.452 240.285L110.927 227.565C107.181 228.983 103.269 230.035 99.2103 230.697L95.5825 247.5C93.5309 247.662 91.4713 247.812 89.375 247.812C87.2787 247.812 85.2191 247.662 83.1634 247.5L79.5356 230.697C75.4812 230.035 71.565 228.983 67.8194 227.565L56.2941 240.285C52.5403 238.501 48.9328 236.47 45.5447 234.126L50.8097 217.795C47.6613 215.219 44.7809 212.335 42.2053 209.19L25.8741 214.455C23.53 211.067 21.5028 207.46 19.7153 203.706L32.435 192.181C31.0172 188.435 29.965 184.523 29.3028 180.464L12.5003 176.837C12.3378 174.781 12.1875 172.721 12.1875 170.625C12.1875 168.529 12.3378 166.469 12.5003 164.413L29.3028 160.786C29.965 156.731 31.0172 152.815 32.435 149.069L19.7153 137.544C21.4988 133.79 23.53 130.183 25.8741 126.795L42.2053 132.06C44.7809 128.911 47.6653 126.031 50.8138 123.455L45.5487 107.124C48.9369 104.78 52.5444 102.753 56.2981 100.965L67.8234 113.685C71.5691 112.267 75.4813 111.215 79.5397 110.553L83.1675 93.7503C85.2191 93.5878 87.2787 93.4375 89.375 93.4375C91.4713 93.4375 93.5309 93.5878 95.5866 93.7503L99.2144 110.553C103.269 111.215 107.185 112.267 110.931 113.685L122.456 100.965C126.21 102.749 129.817 104.78 133.205 107.124L127.94 123.455C131.089 126.031 133.969 128.915 136.545 132.064L152.876 126.799C155.22 130.187 157.247 133.794 159.035 137.548L146.315 149.073C147.733 152.819 148.785 156.731 149.447 160.79L166.25 164.418C166.412 166.469 166.562 168.529 166.562 170.625C166.562 172.721 166.412 174.781 166.25 176.837L149.447 180.464ZM89.375 130C66.9378 130 48.75 148.188 48.75 170.625C48.75 193.062 66.9378 211.25 89.375 211.25C111.812 211.25 130 193.062 130 170.625C130 148.188 111.812 130 89.375 130Z"
          fill="#AA000F"
        />
        <path
          d="M205.802 66.2391C205.168 68.3394 204.34 70.3544 203.32 72.2516L209.735 84.5447C207.569 87.2828 205.099 89.7528 202.361 91.9181L190.068 85.5034C188.171 86.5272 186.156 87.3559 184.056 87.9856L179.896 101.229C178.181 101.432 176.451 101.562 174.688 101.562C172.924 101.562 171.194 101.433 169.483 101.233L165.323 87.9897C163.223 87.36 161.208 86.5272 159.311 85.5075L147.018 91.9222C144.28 89.7569 141.81 87.2869 139.644 84.5488L146.059 72.2556C145.035 70.3584 144.207 68.3434 143.577 66.2431L130.333 62.0831C130.13 60.3687 130 58.6381 130 56.875C130 55.1119 130.13 53.3812 130.329 51.6709L143.573 47.5109C144.202 45.4106 145.035 43.3956 146.055 41.4984L139.64 29.2053C141.806 26.4672 144.276 23.9972 147.014 21.8319L159.307 28.2466C161.204 27.2228 163.219 26.3941 165.319 25.7644L169.479 12.5206C171.194 12.3175 172.924 12.1875 174.688 12.1875C176.451 12.1875 178.181 12.3175 179.892 12.5166L184.052 25.7603C186.152 26.39 188.167 27.2228 190.064 28.2425L202.357 21.8278C205.095 23.9931 207.565 26.4631 209.731 29.2013L203.316 41.4944C204.34 43.3916 205.168 45.4066 205.798 47.5069L219.042 51.6669C219.245 53.3813 219.375 55.1119 219.375 56.875C219.375 58.6381 219.245 60.3688 219.046 62.0791L205.802 66.2391ZM174.688 40.625C165.713 40.625 158.438 47.9009 158.438 56.875C158.438 65.8491 165.713 73.125 174.688 73.125C183.662 73.125 190.938 65.8491 190.938 56.875C190.938 47.9009 183.662 40.625 174.688 40.625Z"
          fill="#AA000F"
        />
        <path
          d="M238.627 157.913C238.002 160.168 237.12 162.305 235.991 164.3L240.033 172.807C238.197 175.151 236.088 177.259 233.744 179.095L225.237 175.053C223.247 176.182 221.106 177.064 218.851 177.69L215.69 186.578C214.232 186.757 212.757 186.875 211.25 186.875C209.743 186.875 208.268 186.757 206.81 186.578L203.649 177.69C201.394 177.064 199.257 176.182 197.263 175.053L188.756 179.095C186.412 177.259 184.303 175.151 182.467 172.807L186.509 164.3C185.38 162.309 184.498 160.168 183.873 157.918L174.984 154.757C174.805 153.294 174.688 151.82 174.688 150.312C174.688 148.805 174.805 147.331 174.984 145.872L183.873 142.712C184.498 140.457 185.38 138.32 186.509 136.329L182.467 127.823C184.303 125.478 186.412 123.37 188.756 121.534L197.263 125.576C199.253 124.447 201.394 123.565 203.649 122.939L206.81 114.051C208.268 113.868 209.743 113.75 211.25 113.75C212.757 113.75 214.232 113.868 215.69 114.047L218.851 122.935C221.106 123.561 223.243 124.442 225.237 125.572L233.744 121.53C236.088 123.366 238.197 125.474 240.033 127.818L235.991 136.325C237.12 138.316 238.002 140.457 238.627 142.712L247.516 145.872C247.695 147.331 247.812 148.805 247.812 150.312C247.812 151.82 247.695 153.294 247.516 154.753L238.627 157.913ZM211.25 138.125C204.518 138.125 199.062 143.581 199.062 150.312C199.062 157.044 204.518 162.5 211.25 162.5C217.982 162.5 223.438 157.044 223.438 150.312C223.438 143.581 217.982 138.125 211.25 138.125Z"
          fill="#AA000F"
        />
        <path
          d="M248.609 20.3937L224.311 15.5349L219.452 39.8287L227.419 41.4252L229.608 30.489C233.545 38.679 235.625 47.7059 235.625 56.8749C235.625 70.8824 231.002 84.053 222.259 94.9609L228.601 100.043C238.371 87.8515 243.75 72.5237 243.75 56.8749C243.75 46.2352 241.292 35.7458 236.616 26.2802L247.016 28.3602L248.609 20.3937Z"
          fill="#AA000F"
        />
        <path
          d="M96.3102 54.0029L90.5658 59.7473L96.0867 65.2682C93.8523 65.1261 91.6139 65.0001 89.3755 65.0001C57.948 65.0001 28.3648 78.8573 8.21484 103.021L14.4548 108.225C33.057 85.9179 60.3611 73.1251 89.3755 73.1251C91.4433 73.1251 93.507 73.2429 95.5708 73.3729L90.5658 78.3779L96.3102 84.1223L111.37 69.0626L96.3102 54.0029Z"
          fill="#AA000F"
        />
      </svg>
    </Icon>
  );
};

export const Card: React.FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M19.9219 4.6875H5.07812C3.56797 4.6875 2.34375 5.91172 2.34375 7.42188V17.5781C2.34375 19.0883 3.56797 20.3125 5.07812 20.3125H19.9219C21.432 20.3125 22.6562 19.0883 22.6562 17.5781V7.42188C22.6562 5.91172 21.432 4.6875 19.9219 4.6875Z"
        stroke="#FAFAFA"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.34375 9.375H22.6562"
        stroke="#FAFAFA"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M8.59375 14.6484H6.25V15.625H8.59375V14.6484Z"
        stroke="#FAFAFA"
        stroke-width="2"
        stroke-linejoin="round"
      />
    </Icon>
  );
};

export const CheckMark: React.FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M22.7051 6.20117L11.7676 18.75L7.27539 14.2578"
        stroke="#FAFAFA"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M6.83594 18.7988L2.29492 14.2578"
        stroke="#FAFAFA"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M17.7246 6.20117L11.5234 13.3301"
        stroke="#FAFAFA"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </Icon>
  );
};

export const GreenCheckMark: React.FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M22.5 4.21875C12.4198 4.21875 4.21875 12.4198 4.21875 22.5C4.21875 32.5802 12.4198 40.7812 22.5 40.7812C32.5802 40.7812 40.7812 32.5802 40.7812 22.5C40.7812 12.4198 32.5802 4.21875 22.5 4.21875ZM19.1602 31.674L12.0762 23.8017L14.1671 21.9199L19.089 27.3885L30.7617 13.4877L32.9186 15.293L19.1602 31.674Z"
        fill="#93C840"
      />
    </Icon>
  );
};

export const DiscountProduct: React.FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M103.487 48.8327L93.2154 62.183L93.6065 78.9636L77.3664 83.7742L67.7349 97.5926L51.7433 92.0152L35.7518 97.5926L26.1202 83.7742L9.88012 78.9636L10.2713 62.183L0 48.8327L10.2713 35.4865L9.88012 18.7019L26.1202 13.8953L35.7518 0.0769043L51.7433 5.65027L67.7349 0.0769043L77.3664 13.8953L93.6065 18.7019L93.2154 35.4865L103.487 48.8327Z"
        fill="#93C840"
      />
    </Icon>
  );
};
