import React from "react";
import { BiCategory } from "react-icons/bi";
import { FaRegUserCircle } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import { IoMdHome } from "react-icons/io";
import { Link } from "react-router-dom";
import CategoriesBar from "../components/molecules/CategoriesBar";
import Features from "../components/molecules/Features";
import Footer from "../components/organisms/Footer";
import Header from "../components/organisms/Header";
import { useTypedSelector } from "../hooks/useSelector";

interface DefaultLayoutProps {
  children: React.ReactNode;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
  const { isAuthenticated } = useTypedSelector((state) => state.users);
  const { total_product_quantity } = useTypedSelector((state) => state.cart);

  return (
    <main className="relative">
      <Header />
      <CategoriesBar />

      {/* Main Content */}
      {children}
      {/* End of Main Content */}

      <div className="md:hidden fixed bottom-0 left-0 right-0 w-full bg-white z-50">
        <div
          className="h-16 w-full border-t-2 border-opacity-50 flex justify-between items-center md:px-8 px-5"
          style={{ boxShadow: "-1px -1px 10px 2px rgba(0, 0, 0, 0.05)" }}
        >
          <Link to="/">
            <div className="flex justify-center items-center flex-col">
              <IoMdHome size={24} />
              <span className="text-sm">Home</span>
            </div>
          </Link>
          <Link
            to="/products/categories"
            className="hover:text-red duration-200 relative"
          >
            <div className="flex justify-center items-center flex-col">
              <BiCategory size={20} />
              <span className="text-sm">Categories</span>
            </div>
          </Link>
          <Link to="/cart" className="hover:text-red duration-200 relative">
            <div className="flex justify-center items-center flex-col relative">
              <FiShoppingCart size={20} />
              {isAuthenticated && (
                <span
                  className="absolute -top-3 -right-4 rounded-full flex justify-center items-center p-2 h-6 text-white bg-red font-semibold"
                  style={{ fontSize: 10 }}
                >
                  {total_product_quantity}
                </span>
              )}
              <span className="text-sm">Cart</span>
            </div>
          </Link>
          <Link to="/account/profile">
            <div className="flex justify-center items-center flex-col">
              <div className="flex items-center ml-2 hover:text-red duration-200">
                <FaRegUserCircle />
              </div>
              <span className="text-sm">Profile</span>
            </div>
          </Link>
        </div>
      </div>
      <Features />
      <Footer />
    </main>
  );
};

export default DefaultLayout;
