import { combineReducers } from "redux";
import cartReducer from "./cartReducer";
import favoritesReducer from "./favoritesReducer";
import homeReducer from "./homeReducer";
import productReducer from "./productsReducer";
import searchReducer from "./searchReducer";
import sellerReducer from "./sellerReducer";
import userAddressReducer from "./userAddressReducer";
import userReducer from "./userReducer";

const reducers = combineReducers({
  home: homeReducer,
  users: userReducer,
  seller: sellerReducer,
  address: userAddressReducer,
  products: productReducer,
  cart: cartReducer,
  favorites: favoritesReducer,
  search: searchReducer,
});

export type RootState = ReturnType<typeof reducers>;

export default reducers;
