import { UserActionType } from "../action-types";
import { UserAction } from "../actions/user";

let userProfile: UserProfileType = localStorage.getItem("aanbhi_user_profile")
  ? JSON.parse(localStorage.getItem("aanbhi_user_profile") || "")
  : null;

let userReward: UserReward = localStorage.getItem("aanbhi_user_reward")
  ? JSON.parse(localStorage.getItem("aanbhi_user_reward") || "")
  : null;

const initialState: {
  profile: UserProfileType;
  isAuthenticated: boolean;
  reward: UserReward;
} = {
  isAuthenticated: userProfile?.email ? true : false,
  profile: {
    id: userProfile?.id || "",
    full_name: userProfile?.full_name || "",
    phone: userProfile?.phone || "",
    email: userProfile?.email || "",
    image: userProfile?.image || null,
    dob: userProfile?.dob || null,
    referral_code: userProfile?.referral_code || "",
    is_seller: userProfile?.is_seller || false,
  },
  reward: {
    id: userReward?.id || "",
    reward_point: userReward?.reward_point || 0,
    user: userReward?.user || "",
  },
};

const userReducer = (state = initialState, action: UserAction) => {
  switch (action.type) {
    case UserActionType.GET_USER_PROFILE_SUCCESS:
      localStorage.setItem(
        "aanbhi_user_profile",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        isAuthenticated: true,
        profile: action.payload,
      };
    case UserActionType.UPDATE_USER_PROFILE_SUCCESS:
      const newData = {
        ...state,
        ...action.payload,
      };
      localStorage.setItem("aanbhi_user_profile", JSON.stringify(newData));
      return {
        ...state,
        isAuthenticated: true,
        profile: newData,
      };
    case UserActionType.GET_REWARD_POINTS_SUCCESS:
      localStorage.setItem(
        "aanbhi_user_rewards",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        reward: action.payload,
      };

    case UserActionType.USER_LOGIN_FAILED:
    case UserActionType.GET_USER_PROFILE_FAILED: {
      localStorage.removeItem("aanbhi_user_profile");

      return {
        ...state,
        isAuthenticated: false,
        profile: {
          full_name: "",
          phone: "",
          email: "",
          image: null,
          dob: null,
          is_seller: false,
          referral_code: "",
        },
      };
    }

    case UserActionType.USER_LOGOUT:
      localStorage.removeItem("aanbhi_user_profile");
      localStorage.removeItem("aanbhi_seller_profile");
      localStorage.removeItem("aanbhi_rewards");
      localStorage.removeItem("aanbhi_cart");
      return {
        ...state,
        isAuthenticated: false,
        profile: {
          full_name: "",
          phone: "",
          email: "",
          image: null,
          dob: null,
          is_seller: false,
          referral_code: "",
        },
        reward: {
          id: "",
          reward_point: 0,
          user: "",
        },
      };

    case UserActionType.USER_LOGIN_SUCCESS:
    case UserActionType.USER_REGISTER_SUCCESS:
    case UserActionType.USER_REGISTER_FAILED:
    default:
      return state;
  }
};

export default userReducer;
