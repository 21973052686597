import clsx from "clsx";
import React from "react";

interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  size?: number;
  variant?: "primary" | "secondary";
  small?: boolean;
  block?: boolean;
  disabled?: boolean;
  type?: "submit" | "reset" | "button";
}

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  small,
  variant = "primary",
  disabled = false,
  block = false,
  size,
  type = "button",
  ...props
}) => {
  return (
    <button
      type={type}
      className={clsx(
        "text-sm sm:text-lg font-medium, border-2 border-primary-teal rounded-md duration-200",
        variant === "primary" &&
          "bg-red border-red hover:bg-red-dark text-white",
        variant === "secondary" &&
          "border-2 border-red bg-primary-white text-red hover:bg-background",
        className,
        small ? "py-1 px-12" : "py-2 px-12",
        disabled && "cursor-not-allowed"
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
