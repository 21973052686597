import { Dispatch } from "redux";
import { UserActionType, UserAddressActionType } from "../action-types/index";
import { getToken } from "./helper";
import axios, { AxiosResponse } from "axios";
import { API } from "../../config";

export const getAddress = () => async (dispatch: Dispatch) => {
  try {
    const response: AxiosResponse = await axios.get(`${API}/user/address/`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });

    dispatch({
      type: UserAddressActionType.GET_USER_ADDRESS_SUCCESS,
      payload: response?.data?.results,
    });
    return response;
  } catch (e: any) {
    dispatch({ type: UserAddressActionType.GET_USER_ADDRESS_FAILED });
    dispatch({ type: UserActionType.USER_LOGOUT });
    return e.response;
  }
};

export const addAddress =
  (data: {
    full_name: string;
    phone: string;
    city: string;
    area: string;
    exact_description: string;
    landmark: string;
    is_default: boolean;
  }) =>
  async (dispatch: Dispatch) => {
    try {
      const response: AxiosResponse = await axios.post(
        `${API}/user/address/create/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response;
    } catch (error: any) {
      dispatch({ type: UserActionType.USER_LOGOUT });
      return error.response;
    }
  };

export const editAddress =
  (
    id: string | number,
    data: {
      full_name: string;
      phone: string;
      city: string;
      area: string;
      exact_description: string;
      landmark: string;
      is_default: boolean;
    }
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const response: AxiosResponse = await axios.patch(
        `${API}/user/address/update/${id}/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      dispatch({
        type: UserAddressActionType.EDIT_USER_ADDRESS_SUCCESS,
        payload: id,
      });
      return response;
    } catch (e: any) {
      dispatch({ type: UserAddressActionType.EDIT_USER_ADDRESS_FAILED });
      dispatch({ type: UserActionType.USER_LOGOUT });
      return e.response;
    }
  };

export const deleteAddress =
  (id: string | number) => async (dispatch: Dispatch) => {
    try {
      const response: AxiosResponse = await axios.delete(
        `${API}/user/address/delete/${id}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      dispatch({
        type: UserAddressActionType.DELETE_USER_ADDRESS_SUCCESS,
        payload: id,
      });
      return response;
    } catch (e: any) {
      dispatch({ type: UserActionType.USER_LOGOUT });
      dispatch({ type: UserAddressActionType.DELETE_USER_ADDRESS_FAILED });
      return e.response;
    }
  };
