import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface AlertProps {
  loading?: boolean;
  failed?: boolean;
  success?: boolean;
  message: string;
}

const Alert: React.FC<AlertProps> = ({ loading, failed, success, message }) => {
  useEffect(() => {
    if (failed) {
      toast.error(message);
    }

    if (success) {
      toast.success(message);
    }
  }, [message, failed, success, loading]);

  return (
    <ToastContainer
      position="top-right"
      pauseOnHover={false}
      autoClose={1500}
      limit={1}
      style={{ fontSize: 16 }}
    />
  );
};

export default Alert;
