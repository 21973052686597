import axios from "axios";
import clsx from "clsx";
import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { API } from "../../../config";
import { getToken } from "../../../redux/action-creators/helper";
import {
  validateNcellNumber,
  validateNepaliNumber,
  validateNTCNumber,
  validateSmallNumber,
} from "../../../utils/validation";
import TopUpImg from "../../../assets/topup.svg";
import Alert from "../../atomic/Alert";
import Button from "../../atomic/Button";
import Select from "react-select";
import FormControl from "../../molecules/FormControl";
import { useTypedSelector } from "../../../hooks/useSelector";
import { useActions } from "../../../hooks/useAction";

type StateValueType = {
  service: "Ncell" | "NTC" | "Smart";
  number: string;
  amount: number;
  error: {
    service?: string;
    number?: string;
    amount?: string;
  };
  loading: boolean;
  success: boolean;
  failed: boolean;
  message: string;
};

type PropsType = {
  show: boolean;
  handleTopUpShow: () => void;
};

const TopUpModal: React.FC<PropsType> = ({ show, handleTopUpShow }) => {
  const history = useHistory();
  const { logo } = useTypedSelector((state) => state.home);
  const [values, setValues] = useState<StateValueType>({
    service: "Ncell",
    number: "",
    amount: 0,
    error: {
      service: "",
      number: "",
      amount: "",
    },
    loading: false,
    success: false,
    failed: false,
    message: "",
  });

  const [smartAmount, setSmartAmount] = useState<any>({
    value: 20,
    label: 20,
  });

  const { reward } = useTypedSelector((state) => state.users);

  const { service, number, amount, error, loading, success, failed, message } =
    values;

  const { getRewardPoints } = useActions();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
      error: {
        service: "",
        number: "",
        amount: "",
      },
      loading: false,
      success: false,
      failed: false,
      message: "",
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!number) {
      return setValues({
        ...values,
        error: { number: "Mobile is required." },
      });
    } else if (!validateNepaliNumber(number)) {
      return setValues({
        ...values,
        error: { number: "Enter 10 digit number." },
      });
    } else if (service === "Ncell" && !validateNcellNumber(number)) {
      return setValues({
        ...values,
        error: {
          number: "Invalid Ncell Number.",
        },
      });
    } else if (service === "NTC" && !validateNTCNumber(number)) {
      return setValues({
        ...values,
        error: {
          number: "Invalid NTC Number.",
        },
      });
    } else if (service === "Smart" && !validateSmallNumber(number)) {
      return setValues({
        ...values,
        error: {
          number: "Invalid Smart Number.",
        },
      });
    } else if (service !== "Smart" && !amount) {
      return setValues({
        ...values,
        error: {
          amount: "Amount is required",
        },
      });
    } else if (amount > reward.reward_point) {
      return setValues({
        ...values,
        error: {
          amount: `Amount is greater than ${reward.reward_point}`,
        },
      });
    } else if (service === "NTC" && (amount < 10 || amount > 25000)) {
      return setValues({
        ...values,
        error: {
          amount: "Amount Between 10 - 25000",
        },
      });
    } else if (service === "Ncell" && (amount < 10 || amount > 5000)) {
      return setValues({
        ...values,
        error: {
          amount: "Amount Between 10 - 5000",
        },
      });
    } else {
      setValues({
        ...values,
        error: {
          service: "",
          number: "",
          amount: "",
        },
        loading: true,
        success: false,
        failed: false,
        message: "Submitting...",
      });
    }

    let data = {
      service,
      amount,
      number,
    };

    if (service === "Smart") {
      data = {
        service,
        amount: smartAmount.value,
        number,
      };
    }

    const res: any = await axios.post(`${API}/khalti/topup/`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });

    if (!res || typeof res === undefined || res.status === 500) {
      return setValues({
        ...values,
        loading: false,
        success: false,
        failed: true,
        message: "Internal Server Error",
      });
    }
    if (res.status === 200 || res.status === 201) {
      setValues({
        ...values,
        loading: true,
        success: true,
        failed: false,
        message: "Success",
      });

      const response: any = await axios.post(
        `${API}/khalti/reward/pay/`,
        {
          khaltitopup_id: res.data.khaltitopup_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      if (response.status === 401) {
        setValues({
          ...values,
          loading: false,
          success: false,
          failed: true,
          message: response.data.message || "Sorry Failed.",
        });
      }
      if (response.status === 200) {
        setValues({
          ...values,
          loading: false,
          success: true,
          failed: false,
          message: response.data.message || "Success.",
        });
      }
      setValues({
        ...values,
        loading: false,
        success: false,
        failed: false,
        message: "",
      });

      getRewardPoints();
      handleTopUpShow();
      return;
    }
    if (res.status === 400 || res.status === 401) {
      return setValues({
        ...values,
        loading: false,
        success: false,
        failed: true,
        message: "Invalid Data",
      });
    }
    if (res.status === 403) {
      setValues({
        ...values,
        loading: false,
        success: true,
        failed: false,
        message: "Please Login First",
      });
      setTimeout(() => {
        history.push("/login");
      }, 2000);
      return;
    }
    handleTopUpShow();
  };

  return (
    <div
      className={clsx(
        "fixed top-0 left-0 right-0 bottom-0 w-full h-full z-50",
        !show && "hidden"
      )}
      style={{ background: "rgba(0, 0, 0, 0.7)", zIndex: 100 }}
    >
      <Alert
        success={success}
        failed={failed}
        loading={loading}
        message={message}
      />
      <form
        onSubmit={handleSubmit}
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full bg-white p-3 rounded-md overflow-auto flex justify-between items-center"
        style={{
          minHeight: 320,
          minWidth: 200,
          width: "90vw",
          height: "90vh",
          maxHeight: 400,
          maxWidth: 600,
        }}
      >
        <div className="w-1/2 relative h-full hidden sm:block p-2">
          <img src={TopUpImg} alt="TOPUP" className="w-2/3 absolute bottom-0" />
          <div className="w-full flex justify-center">
            <img src={logo} alt="Aanbhi Logo" className="w-1/2 text-center" />
          </div>
          <h3 className="text-red font-semibold text-base py-3 text-center">
            Aanbhi Topup
          </h3>
        </div>
        <div
          className={
            "absolute top-2 right-2 cursor-pointer w-8 h-8 rounded-full flex justify-center items-center z-50 text-right hover:text-red"
          }
          onClick={() => handleTopUpShow()}
        >
          <IoMdClose size={20} />
        </div>

        <div className="w-full sm:w-1/2 sm:pr-2">
          <h3 className="text-red font-semibold text-xl py-3 text-center sm:hidden">
            Aanbhi Topup
          </h3>
          <div className="flex justify-between my-3 rounded overflow-hidden">
            <button
              type="button"
              className={clsx(
                "p-2 w-full border-red border border-collapse ",
                service === "Ncell" && "bg-red text-white"
              )}
              onClick={() => {
                return setValues({
                  ...values,
                  service: "Ncell",
                  error: {
                    service: "",
                    number: "",
                    amount: "",
                  },
                });
              }}
            >
              Ncell
            </button>
            <button
              type="button"
              className={clsx(
                "p-2 w-full border-red border border-collapse",
                service === "NTC" && "bg-red text-white"
              )}
              onClick={() => {
                return setValues({
                  ...values,
                  service: "NTC",
                  error: {
                    service: "",
                    number: "",
                    amount: "",
                  },
                });
              }}
            >
              NTC
            </button>
            <button
              type="button"
              className={clsx(
                "p-2 w-full border-red border border-collapse",
                service === "Smart" && "bg-red text-white"
              )}
              onClick={() => {
                return setValues({
                  ...values,
                  service: "Smart",
                  error: {
                    service: "",
                    number: "",
                    amount: "",
                  },
                });
              }}
            >
              Smart
            </button>
          </div>
          <FormControl
            required
            type="number"
            label="Mobile"
            name="number"
            value={number}
            handleChange={handleChange}
            error={error.number}
          />
          {service !== "Smart" && (
            <FormControl
              required
              maxLength={10}
              label="Amount"
              name="amount"
              value={amount}
              handleChange={handleChange}
              error={error.amount}
            />
          )}

          {service === "Smart" && (
            <div>
              <div className="flex flex-col my-3">
                <label className="text-base sm:text-lg mb-1">Amount</label>
                <div className="relative w-full">
                  <Select
                    value={smartAmount}
                    onChange={(val) => setSmartAmount(val)}
                    options={[
                      { value: 20, label: 20 },
                      { value: 50, label: 50 },
                      { value: 100, label: 100 },
                      { value: 200, label: 200 },
                      { value: 500, label: 500 },
                      { value: 1000, label: 1000 },
                    ]}
                  />
                </div>
              </div>
            </div>
          )}

          <Button disabled={loading} type="submit" className="w-full mt-3">
            {loading ? "Loading" : "Proceed"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default TopUpModal;
