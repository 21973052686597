import clsx from "clsx";
import React from "react";
import "./Input.styles.scss";

interface InputProps {
  type?: "text" | "email" | "number" | "password" | "search" | "date";
  value: string | number;
  small?: boolean;
  handleChange: (
    value: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  name: string;
  inputClassName?: string;
  placeholder?: string;
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
}

const Input = ({
  type = "text",
  name,
  small,
  value,
  inputClassName,
  handleChange,
  placeholder,
  minLength,
  maxLength,
  min,
  max,
  ...props
}: InputProps) => {
  return (
    <input
      name={name}
      type={type}
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
        handleChange && handleChange(e)
      }
      min={min}
      max={max}
      minLength={minLength}
      maxLength={maxLength}
      placeholder={placeholder}
      className={clsx(
        inputClassName,
        "border-2 border-tertiary-teal text-primary-teal outline-none rounded-md duration-150 text-base",
        small ? "py-1 px-2" : "py-2 px-4",
        "input"
      )}
      {...props}
    />
  );
};

export default Input;
