import { ProductActionType } from "../action-types";
import { ProductAction } from "../actions/products";

type Category = {
  id: number;
  name: string;
};

interface HomeInitialState {
  trending: [];
  categories: Category[];
  productDetail: Product | null;
  similarProducts: [];
  reviews: {
    count: number;
    prev: string | null;
    next: string | null;
    results: Reviews[];
  };
}

const initialState: HomeInitialState = {
  trending: [],
  categories: [],
  productDetail: null,
  similarProducts: [],
  reviews: {
    count: 0,
    prev: null,
    next: null,
    results: [],
  },
};

const productReducer = (state = initialState, action: ProductAction) => {
  switch (action.type) {
    case ProductActionType.GET_TRENDING_PRODUCTS_SUCCESS:
      return {
        ...state,
        trending: action.payload,
      };
    case ProductActionType.GET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        productDetail: action.payload.product,
        similarProducts: action.payload.similar_products,
      };
    case ProductActionType.GET_TRENDING_PRODUCTS_FAILED:
      return {
        ...state,
        trending: [],
      };
    case ProductActionType.GET_PRODUCT_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.payload,
      };
    case ProductActionType.GET_PRODUCT_REVIEWS_FAILED:
      return {
        ...state,
        reviews: null,
      };
    case ProductActionType.ADD_PRODUCT_REVIEW_SUCCESS:
      const newReviews = {
        ...state.reviews,
        count: state.reviews.count++,
        results: [action.payload, ...state.reviews.results],
      };
      return {
        ...state,
        reviews: newReviews,
      };
    case ProductActionType.ADD_PRODUCT_REVIEW_FAILED:
      return {
        ...state,
      };
    case ProductActionType.GET_PRODUCT_DETAILS_FAILED:
      return {
        ...state,
        productDetail: null,
        similarProducts: [],
      };
    case ProductActionType.GET_ALL_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
      };
    case ProductActionType.GET_ALL_PRODUCT_CATEGORIES_FAILED:
      return {
        ...state,
        categories: [],
      };
    default:
      return state;
  }
};

export default productReducer;
