import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import LoginPageImage from "../../../assets/login-page.jpg";
import Alert from "../../../components/atomic/Alert";
import Button from "../../../components/atomic/Button";
import FormControl from "../../../components/molecules/FormControl";
import { useActions } from "../../../hooks/useAction";
import { useTypedSelector } from "../../../hooks/useSelector";
import DefaultLayout from "../../../layout/DefaultLayout";
import { validateEmail } from "../../../utils/validation";
import { Helmet } from "react-helmet-async";
import "../Auth.styles.scss";
import { LINK } from "../../../config";

type StateValues = {
  email: string;
  password: string;
  error: {
    email?: string;
    password?: string;
  };
  loading: boolean;
  message: string;
  success: boolean;
  failed: boolean;
};

const Login: React.FC<RouteComponentProps> = ({ history }) => {
  let url = history.location.search.split("?product=")[1];

  const { login } = useActions();
  const [values, setValues] = useState<StateValues>({
    email: "",
    password: "",
    error: {
      email: "",
      password: "",
    },
    loading: false,
    message: "",
    success: false,
    failed: false,
  });

  const { loading, success, message, email, password, error, failed } = values;

  const { isAuthenticated } = useTypedSelector((state) => state.users);
  useEffect(() => {
    if (isAuthenticated && !url) {
      history.push("/account/profile");
    }
  }, [history, isAuthenticated, url]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
      error: {
        email: "",
        password: "",
      },
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      return setValues({
        ...values,
        error: { email: "Email is required." },
      });
    } else if (!validateEmail(email)) {
      return setValues({
        ...values,
        error: {
          email: "Not a valid email",
        },
      });
    } else if (!password) {
      return setValues({
        ...values,
        error: { password: "Password is required." },
      });
    } else if (password.length < 8) {
      return setValues({
        ...values,
        error: {
          password: "Password must be at least 8 char.",
        },
      });
    } else {
      setValues({
        ...values,
        error: { email: "", password: "" },
        loading: true,
        success: false,
        failed: false,
        message: "Signing in...",
      });
    }
    const res: any = await login({
      email,
      password,
    });

    if (!res || typeof res === undefined) {
      return setValues({
        ...values,
        loading: false,
        success: false,
        failed: true,
        message: "Internal Server Error",
      });
    }
    if (res?.data && res.status === 200) {
      setValues({
        ...values,
        email: "",
        password: "",
        loading: false,
        success: true,
        failed: false,
        message: "Logged in.",
      });

      if (history.location.search) {
        if (url) {
          setTimeout(() => {
            history.push(url);
          }, 2000);
        }
      } else {
        setTimeout(() => {
          history.push("/account/profile");
        }, 2000);
      }

      return;
    }
    if (res.status === 400 || res.status === 401) {
      return setValues({
        ...values,
        loading: false,
        success: false,
        failed: true,
        message:
          (res.data?.message?.length > 0 && res.data?.message[0]) ||
          (res.data?.detail?.length > 0 && res.data?.detail[0]) ||
          "Invalid email or password",
      });
    }
  };

  return (
    <DefaultLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
        <link rel="canonical" href={`${LINK}/login`} />
      </Helmet>
      <Alert
        success={success}
        failed={failed}
        loading={loading}
        message={message}
      />
      <div className="max-w-6xl mx-auto px-6 py-12 flex justify-between">
        <div className="text-center mr-10 hidden sm:block">
          <h2 className="text-red font-semibold text-2xl">
            Welcome To Aanbhi Market Place
          </h2>
          <div className="mx-auto mt-10">
            <img src={LoginPageImage} className='img-fluid'  alt="LoginPageImage" />
          </div>
        </div>
        <form className="w-full sm:w-3/4 ml-auto" onSubmit={handleSubmit}>
          <section className="w-full sm:w-80 lg:w-96 max-w-md mx-auto shadow-2xl drop-shadow-xl p-5 sm:p-8 text-gray-700">
            <h1 className="text-red text-3xl">Login</h1>
            <FormControl
              required
              label="Email"
              value={values.email}
              name="email"
              handleChange={handleChange}
              error={error.email}
            />

            <FormControl
              required
              label="Password"
              type="password"
              value={values.password}
              name="password"
              handleChange={handleChange}
              error={error.password}
            />
            <div className="text-base">
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>
            <Button type="submit" disabled={loading} className="w-full my-3">
              {loading ? "Loading..." : "Login"}
            </Button>

            <div className="mt-5">
              <p>Don't have an account?</p>
              <Link to="/register">
                <Button variant="secondary" className="w-full my-3">
                  Register
                </Button>
              </Link>
            </div>
          </section>
        </form>
      </div>
    </DefaultLayout>
  );
};

export default Login;
