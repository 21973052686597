import React, { useEffect, useState } from "react";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { IoNotificationsOutline } from "react-icons/io5";
import Paypal from "../../../assets/paypal.png";
import Khalti from "../../../assets/khalti.png";
import LinePay from "../../../assets/linepay.svg";
import Amex from "../../../assets/amex.svg";
import Diners from "../../../assets/diners.svg";
import Discover from "../../../assets/discover.svg";
import JCB from "../../../assets/jcb.svg";
import Mastercard from "../../../assets/mastercard.svg";
import Visa from "../../../assets/visa.svg";
import PlayStore from "../../../assets/play-store.png";
import AppStore from "../../../assets/app-store.png";
import { Link } from "react-router-dom";
import "./Footer.styles.scss";
import { validateEmail } from "../../../utils/validation";
import { useActions } from "../../../hooks/useAction";
import Alert from "../../atomic/Alert";
import clsx from "clsx";
import { useTypedSelector } from "../../../hooks/useSelector";
import axios, { AxiosResponse } from "axios";
import { API } from "../../../config";

type StateValues = {
  email: string;

  error: {
    email?: string;
  };
  loading: boolean;
  message: string;
  success: boolean;
  failed: boolean;
};

const Footer: React.FC = () => {
  const { subscribe } = useActions();
  const { footerCategories } = useTypedSelector((state) => state.home);
  const [values, setValues] = useState<StateValues>({
    email: "",
    error: {
      email: "",
    },
    loading: false,
    message: "",
    success: false,
    failed: false,
  });

  const [userPolicyLinks, setUserPolicyLinks] = useState([]);
  const [sellerLinks, setSellerLinks] = useState([]);
  const [customerLinks, setCustomerLinks] = useState([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
      error: {
        email: "",
      },
    });
  };

  const { loading, success, message, email, error, failed } = values;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      return setValues({
        ...values,
        error: { email: "Email is required." },
      });
    } else if (!validateEmail(email)) {
      return setValues({
        ...values,
        error: {
          email: "Not a valid email",
        },
      });
    } else {
      setValues({
        ...values,
        error: { email: "" },
        loading: true,
        success: false,
        failed: false,
        message: "Sending...",
      });
    }

    const res: any = await subscribe({
      email,
    });

    if (!res || typeof res === undefined) {
      return setValues({
        ...values,
        loading: false,
        success: false,
        failed: true,
        message: "Internal Server Error",
      });
    }
    if (res?.data || res.status === 201) {
      return setValues({
        ...values,
        email: "",
        loading: false,
        success: true,
        failed: false,
        message: "Subscribed.",
      });
    }
    if (res.status === 400 || res.status === 401) {
      return setValues({
        ...values,
        loading: false,
        success: false,
        failed: true,
        message: "Invalid email",
      });
    }
  };

  const year = new Date().getFullYear();

  useEffect(() => {
    getCustomerCareLinks();
    getSellerLinks();
    getUserPolicyLinks();
  }, []);

  const getCustomerCareLinks = async () => {
    try {
      const res: AxiosResponse = await axios.get(
        `${API}/info/?is_customercare=true`
      );
      setCustomerLinks(res.data);
    } catch (error) {
      setCustomerLinks([]);
    }
  };

  const getSellerLinks = async () => {
    try {
      const res: AxiosResponse = await axios.get(
        `${API}/info/?is_sellers=true`
      );
      setSellerLinks(res.data);
    } catch (error) {
      setSellerLinks([]);
    }
  };

  const getUserPolicyLinks = async () => {
    try {
      const res: AxiosResponse = await axios.get(
        `${API}/info/?is_userpolicy=true`
      );
      setUserPolicyLinks(res.data);
    } catch (error) {
      setUserPolicyLinks([]);
    }
  };

  return (
    <footer className="bg-background py-10 px-5">
      <Alert
        success={success}
        failed={failed}
        loading={loading}
        message={message}
      />
      <div className="max-w-6xl mx-auto text-sm">
        <div className="flex justify-between flex-wrap text-gray-600 py-5">
          <div className="p-2 w-1/2 sm:w-auto">
            <h4 className="footer-header">Customer Care</h4>
            <Link to="/about">
              <h5 className="footer-link">About Us</h5>
            </Link>
            <Link to="/cart">
              <h5 className="footer-link">My Cart</h5>
            </Link>
            <Link to="/wishlist">
              <h5 className="footer-link">Wishlist</h5>
            </Link>
            {customerLinks.length > 0 &&
              customerLinks.map((info: any) => {
                return (
                  <Link to={`/info/${info.slug}`} key={info.slug}>
                    <h5 className="footer-link">{info.title}</h5>
                  </Link>
                );
              })}
          </div>
          <div className="p-2 w-1/2 sm:w-auto">
            <h4 className="footer-header">Sellers</h4>
            {sellerLinks.length > 0 &&
              sellerLinks.map((info: any) => {
                return (
                  <Link to={`/info/${info.slug}`} key={info.slug}>
                    <h5 className="footer-link">{info.title}</h5>
                  </Link>
                );
              })}
          </div>
          <div className="p-2 w-1/2 sm:w-auto">
            <h4 className="footer-header">Users Policy</h4>
            {userPolicyLinks.length > 0 &&
              userPolicyLinks.map((info: any) => {
                return (
                  <Link to={`/info/${info.slug}`} key={info.slug}>
                    <h5 className="footer-link">{info.title}</h5>
                  </Link>
                );
              })}
          </div>
          <div className="flex flex-col p-2 w-1/2 sm:w-auto">
            <div>
              <h4 className="footer-header">Connect With Us</h4>
              <ul className="flex items-center text-gray-700">
                <li className="hover:text-red duration-200 transform mr-6">
                  <a
                    href="https://www.facebook.com/aanbhimarketplace"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaFacebook size={24} />
                  </a>
                </li>
                <li className="hover:text-red duration-200 transform mr-6">
                  <a
                    href="https://www.instagram.com/aanbhimarketplace/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaInstagram size={24} />
                  </a>
                </li>
                <li className="hover:text-red duration-200 transform mr-6">
                  <a
                    href="https://www.twitter.com/aanbhimarketplace/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaTwitter size={24} />
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <div className="">
                <h4 className="footer-header">Coming Soon...</h4>
                <img
                  src={PlayStore}
                  alt="Play Store"
                  className="w-full mb-2"
                  style={{ maxWidth: 150 }}
                />
                <img
                  src={AppStore}
                  alt="App Store"
                  className="w-full"
                  style={{ maxWidth: 150 }}
                />
              </div>
            </div>
          </div>
          <div className="w-full sm:w-min m-2">
            <h4 className="footer-header">Stay Connected</h4>
            <span className="text-red text-sm">{error.email}</span>
            <form
              className="bg-white rounded-md py-3 px-4 relative mb-10 w-full"
              style={{ minWidth: 270 }}
              onSubmit={handleSubmit}
            >
              <input
                type="email"
                name="email"
                value={email}
                placeholder="Your Email"
                className="outline-none w-1/2"
                onChange={handleChange}
              />
              <button
                type="submit"
                disabled={loading}
                className={clsx(
                  "flex items-center py-3 px-4 border-2 border-primary-teal rounded-md bg-red text-white hover:bg-red-dark duration-200 absolute top-0 right-0",
                  loading ? "cursor-not-allowed" : "cursor-pointer"
                )}
              >
                <span className="mr-2 text-sm">Subscribe</span>
                <IoNotificationsOutline size={16} />
              </button>
            </form>
          </div>
        </div>
        <hr />
        <div>
          <h4 className="text-black uppercase font-semibold mt-5">
            Our Categories
          </h4>
          {footerCategories && footerCategories.length > 0 && (
            <div className="w-full flex flex-wrap text-gray-600 py-3">
              {footerCategories.map((category: Categories) => {
                return (
                  <div
                    className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 px-1"
                    key={category.id}
                  >
                    <Link
                      to={`/products/category/${category.name}/${category.id}`}
                    >
                      <h5 className="footer-link">{category.name}</h5>
                    </Link>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <hr />
        <h4 className="text-black uppercase font-semibold mt-5">
          Payment Partners
        </h4>
        <ul className="flex flex-wrap items-center justify-center text-gray-700 py-3">
          <li className="m-3">
            <img width="100" src={Khalti} alt="Khalti" />
          </li>
          <li className="m-3">
            <img width="100" src={LinePay} alt="LinePay" />
          </li>
          <li className="m-3">
            <img width="100" src={Paypal} alt="Paypal" />
          </li>
          <li className="m-2">
            <div className="flex flex-wrap">
              <img width="55" src={Amex} alt="amex" className="pl-1 pb-1" />
              <img width="55" src={Diners} alt="diners" className="pl-1 pb-1" />
              <img
                width="55"
                src={Discover}
                alt="discover"
                className="pl-1 pb-1"
              />
              <img width="55" src={JCB} alt="jcb" className="pl-1 pb-1" />
              <img
                width="55"
                src={Mastercard}
                alt="mastercard"
                className="p-1"
              />
              <img width="55" src={Visa} alt="visa" className="pl-1 pb-1" />
            </div>
          </li>
        </ul>
        <hr />
        <div className="flex flex-col sm:flex-row flex-wrap items-center justify-center text-gray-500 text-sm mt-5">
          <p>Copyright | {year} Aanbhi Marketplace Pvt. Ltd.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
