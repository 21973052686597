export const API = "https://api.aanbhi.co.jp";

export const LINK = "https://aanbhi.co.jp";

export const firebaseConfig = {
  apiKey: "AIzaSyDrxN6Tz4zbNGwsLT7UEFzCVf09n2aoeL4",
  authDomain: "aanbhi-marketplace.firebaseapp.com",
  projectId: "aanbhi-marketplace",
  storageBucket: "aanbhi-marketplace.appspot.com",
  messagingSenderId: "211341223614",
  appId: "1:211341223614:web:4c956cf147c01e517e60ef",
  measurementId: "G-0XJ7J2PKD3",
};

export const firebasePublicKey =
  "BBwFPGKkbOJPsOeOlWK2bCUG74eN0taqk_Hyv5RkufgLt0XBv6QsA_gXvuSihWcLN1_kxKA5Eqrh713Pv9yJgo8";

export const PayPalClientId =
  "AVhfqzJGftChR_IIvam--GTImW18i8QCMXm-fQDvqX8zETqRfzTB-6Qfd320Ph5WjPTtXNRUQxeO020P";
