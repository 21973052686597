import { Dispatch } from "redux";
import { FavoritesActionType } from "../action-types";

export const addToFavorites = (data: Product) => (dispatch: Dispatch) => {
  dispatch({
    type: FavoritesActionType.ADD_FAVORITE_PRODUCT,
    payload: data,
  });
};

export const removeFromFavorites =
  (id: string | number) => (dispatch: Dispatch) => {
    dispatch({
      type: FavoritesActionType.REMOVE_FAVORITE_PRODUCT,
      payload: id,
    });
  };
