import { Dispatch } from "redux";
import { ProductActionType, SearchActionType } from "../action-types/index";
import axios, { AxiosResponse } from "axios";
import { API } from "../../config";
import { getToken } from "./helper";

export const getTrendingProducts = () => async (dispatch: Dispatch) => {
  try {
    const response: AxiosResponse = await axios.get(
      `${API}/product?is_trending=true`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    dispatch({
      type: ProductActionType.GET_TRENDING_PRODUCTS_SUCCESS,
      payload: response.data,
    });
    return response;
  } catch (e: any) {
    dispatch({ type: ProductActionType.GET_TRENDING_PRODUCTS_FAILED });
    return e.response;
  }
};

export const getProductDetails = (id: string) => async (dispatch: Dispatch) => {
  try {
    const res: AxiosResponse = await axios.get(`${API}/product/${id}/`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    dispatch({
      type: ProductActionType.GET_PRODUCT_DETAILS_SUCCESS,
      payload: res.data,
    });
    return res;
  } catch (error: any) {
    dispatch({ type: ProductActionType.GET_PRODUCT_DETAILS_FAILED });
    return error.response;
  }
};

export const getProductReview =
  (id: string | number, page: number) => async (dispatch: Dispatch) => {
    try {
      const res: AxiosResponse = await axios.get(
        `${API}/product/ratingbyproduct/${id}?page=${page}`
      );
      dispatch({
        type: ProductActionType.GET_PRODUCT_REVIEWS_SUCCESS,
        payload: res.data,
      });
      return res;
    } catch (error: any) {
      dispatch({ type: ProductActionType.GET_TRENDING_PRODUCTS_FAILED });
      return error.response;
    }
  };

export const addProductReview =
  (data: { rating: number; review: string; product: number | string }) =>
  async (dispatch: Dispatch) => {
    try {
      const res: AxiosResponse = await axios.post(
        `${API}/product/rating/create/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      dispatch({
        type: ProductActionType.ADD_PRODUCT_REVIEW_SUCCESS,
        payload: res.data,
      });
      return res;
    } catch (error: any) {
      dispatch({ type: ProductActionType.ADD_PRODUCT_REVIEW_FAILED });
      return error.response;
    }
  };

export const getAllProductsCategories = () => async (dispatch: Dispatch) => {
  try {
    const res: AxiosResponse = await axios.get(`${API}/product/category/`);
    dispatch({
      type: ProductActionType.GET_ALL_PRODUCT_CATEGORIES_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (error: any) {
    dispatch({ type: ProductActionType.GET_ALL_PRODUCT_CATEGORIES_FAILED });
    return error.response;
  }
};

export const getSearchProducts =
  (term: string | unknown, page: number) => async (dispatch: Dispatch) => {
    try {
      const res: AxiosResponse = await axios.get(
        `${API}/product/?search=${term}&page=${page}`
      );
      dispatch({
        type: SearchActionType.GET_SEARCH_PRODUCTS_SUCCESS,
        payload: res.data,
      });
      return res;
    } catch (error: any) {
      dispatch({ type: SearchActionType.GET_SEARCH_PRODUCTS_FAILED });
      return error.response;
    }
  };
