import { CartActionType } from "../action-types";
import { CartAction } from "../actions/cart";
import crypt from "crypto-js";
import { encryptData } from "../../utils/util";

let AanbhiCartIds = JSON.parse(localStorage.getItem("aanbhi_cart") || "[]");

type PromoCodeDiscount = {
  code: string;
  discount: string;
};

interface CartInitialState {
  final_total: number;
  promo_discount: PromoCodeDiscount;
  cart_total_price: number;
  total_product_quantity: number;
  delivery_charge: number;
  cart_product: CartProduct[];
  reward_points: number;
}

let promoDiscount: PromoCodeDiscount = localStorage.getItem("aanbhi_promo_code")
  ? JSON.parse(
      localStorage.getItem("aanbhi_promo_code") || "{code: '0',discount: 0}"
    )
  : {
      code: "0",
      discount: "",
    };

let decryptedDiscount;
if (promoDiscount) {
  decryptedDiscount = JSON.parse(
    crypt.AES.decrypt(promoDiscount.discount, "aanbhi_marketplace111").toString(
      crypt.enc.Utf8
    ) ||
      crypt.AES.decrypt(
        "U2FsdGVkX18xFjIq2w7CbckltxJWG9wejF5ERYt3DeE=",
        "aanbhi_marketplace111"
      ).toString(crypt.enc.Utf8)
  );
}

let initialState: CartInitialState = {
  promo_discount: {
    code: promoDiscount.code || "0",
    discount: decryptedDiscount ? decryptedDiscount : 0,
  },
  final_total: 0,
  cart_total_price: 0,
  total_product_quantity: 0,
  delivery_charge: 0,
  cart_product: [],
  reward_points: 0,
};

const cartReducer = (state = initialState, action: CartAction) => {
  switch (action.type) {
    case CartActionType.GET_ALL_CART_PRODUCTS_SUCCESS:
      let cart_ids = action.payload.cart_product.map(
        (cartProduct: CartProduct) => cartProduct.product.id
      );
      localStorage.setItem("aanbhi_cart", JSON.stringify(cart_ids));
      return {
        ...action.payload,
        promo_discount: state.promo_discount,
        final_total:
          action.payload.total_price -
          (parseFloat(state.promo_discount.toString()) / 100) *
            action.payload.cart_total_price,
        reward_points: 0,
      };
    case CartActionType.ADD_REWARD_POINTS_SUCCESS:
      return {
        ...state,
        reward_points: action.payload,
      };
    case CartActionType.ADD_PRODUCT_TO_CART_SUCCESS:
      action.payload.cart_product.forEach((product: CartProduct) => {
        localStorage.setItem(
          "aanbhi_cart",
          JSON.stringify([...AanbhiCartIds, product.product.id])
        );
      });
      return {
        ...state,
        ...action.payload,
        final_total:
          action.payload.total_price -
          (parseFloat(state.promo_discount.toString()) / 100) *
            action.payload.cart_total_price,
      };
    case CartActionType.UPDATE_PRODUCT_CART_SUCCESS:
      return {
        ...state,
        ...action.payload,
        final_total:
          action.payload.total_price -
          (parseFloat(state.promo_discount.toString()) / 100) *
            action.payload.cart_total_price,
      };
    case CartActionType.DELETE_PRODUCT_CART_SUCCESS:
      localStorage.removeItem("aanbhi_cart");
      action.payload.cart_product.forEach((product: CartProduct) => {
        localStorage.setItem(
          "aanbhi_cart",
          JSON.stringify([...AanbhiCartIds, product.product.id])
        );
      });

      return {
        ...state,
        ...action.payload,
        final_total:
          action.payload.total_price -
          (parseFloat(state.promo_discount.toString()) / 100) *
            action.payload.cart_total_price,
      };
    case CartActionType.REDEEM_PROMO_CODE_SUCCESS:
      localStorage.setItem(
        "aanbhi_promo_code",
        JSON.stringify({
          code: action.payload.code,
          discount: encryptData(action.payload.discount),
        })
      );

      return {
        ...state,
        promo_discount: {
          code: action.payload.code,
          discount: action.payload.discount,
        },
        final_total:
          state.cart_total_price -
          (parseFloat(action.payload.discount.toString()) / 100) *
            state.cart_total_price,
      };
    case CartActionType.GET_ALL_CART_PRODUCTS_FAILED:
    case CartActionType.ADD_PRODUCT_TO_CART_FAILED:
    case CartActionType.DELETE_PRODUCT_CART_FAILED:
    case CartActionType.UPDATE_PRODUCT_CART_FAILED:
    case CartActionType.REDEEM_PROMO_CODE_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default cartReducer;
