import axios, { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { API } from "../../config";
import { CartActionType } from "../action-types";
import { getToken } from "./helper";

export const getAllCartProducts = () => async (dispatch: Dispatch) => {
  try {
    const res: AxiosResponse = await axios.get(`${API}/product/cart/`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });

    dispatch({
      type: CartActionType.GET_ALL_CART_PRODUCTS_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (error: any) {
    dispatch({
      type: CartActionType.GET_ALL_CART_PRODUCTS_FAILED,
    });

    return error.response;
  }
};

export const addToCart = (data: any) => async (dispatch: Dispatch) => {
  try {
    const res: AxiosResponse = await axios.post(
      `${API}/product/cart/add/`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    dispatch({
      type: CartActionType.ADD_PRODUCT_TO_CART_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (error: any) {
    dispatch({
      type: CartActionType.ADD_PRODUCT_TO_CART_FAILED,
    });
    return error.response;
  }
};

export const removeFromCart =
  (id: string | number) => async (dispatch: Dispatch) => {
    try {
      const res: AxiosResponse = await axios.delete(
        `${API}/product/cart/delete/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      dispatch({
        type: CartActionType.DELETE_PRODUCT_CART_SUCCESS,
        payload: res.data,
      });
      return res;
    } catch (error: any) {
      dispatch({
        type: CartActionType.DELETE_PRODUCT_CART_FAILED,
      });
      return error.response;
    }
  };

export const updateCartProduct =
  (data: any, id: string | number) => async (dispatch: Dispatch) => {
    try {
      const res: AxiosResponse = await axios.patch(
        `${API}/product/cart/update/${id}/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      dispatch({
        type: CartActionType.UPDATE_PRODUCT_CART_SUCCESS,
        payload: res.data,
      });
      return res;
    } catch (error: any) {
      dispatch({
        type: CartActionType.UPDATE_PRODUCT_CART_FAILED,
      });
      return error.response;
    }
  };

export const addPromoCode =
  (data: { promo_code: string }) => async (dispatch: Dispatch) => {
    try {
      const res: AxiosResponse = await axios.post(
        `${API}/transaction/promocode/redeem/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      dispatch({
        type: CartActionType.REDEEM_PROMO_CODE_SUCCESS,
        payload: {
          code: data.promo_code,
          discount: res.data.discount_percent,
        },
      });

      return res;
    } catch (error: any) {
      dispatch({
        type: CartActionType.REDEEM_PROMO_CODE_FAILED,
      });
      return error.response;
    }
  };

export const createOrder = (data: any) => async (dispatch: Dispatch) => {
  try {
    const res: AxiosResponse = await axios.post(
      `${API}/transaction/add/`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    localStorage.removeItem("aanbhi_cart");
    localStorage.removeItem("aanbhi_promo_code");
    return res;
  } catch (error: any) {
    return error.response;
  }
};

export const clearCart = () => async (dispatch: Dispatch) => {
  try {
    const res: AxiosResponse = await axios.delete(
      `${API}/product/cart/remove/all/`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    dispatch({
      type: CartActionType.CLEAR_CART_SUCCESS,
      payload: res.data,
    });
    return res;
  } catch (error: any) {
    dispatch({
      type: CartActionType.CLEAR_CART_FAILED,
    });
    return error.response;
  }
};

export const useRewardPoints = (val: number) => (dispatch: Dispatch) => {
  dispatch({
    type: CartActionType.ADD_REWARD_POINTS_SUCCESS,
    payload: val,
  });
};
