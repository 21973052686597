export enum UserActionType {
  USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILED = "USER_LOGIN_FAILED",
  USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS",
  USER_REGISTER_FAILED = "USER_REGISTER_FAILED",
  GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS",
  GET_USER_PROFILE_FAILED = "GET_USER_PROFILE_FAILED",
  UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS",
  GET_REWARD_POINTS_SUCCESS = "GET_REWARD_POINTS_SUCCESS",
  GET_REWARDS_POINTS_FAILED = "GET_REWARDS_POINTS_FAILED",
  USER_LOGOUT = "USER_LOGOUT",
}

export enum SellerActionType {
  GET_SELLER_PROFILE_SUCCESS = "GET_SELLER_PROFILE_SUCCESS",
  GET_SELLER_PROFILE_FAILED = "GET_SELLER_PROFILE_FAILED",
  UPDATE_SELLER_PROFILE_SUCCESS = "UPDATE_SELLER_PROFILE_SUCCESS",
  UPDATE_SELLER_PROFILE_FAILED = "UPDATE_SELLER_PROFILE_FAILED",
}

export enum UserAddressActionType {
  GET_USER_ADDRESS_SUCCESS = "GET_USER_ADDRESS_SUCCESS",
  GET_USER_ADDRESS_FAILED = "GET_USER_ADDRESS_FAILED",
  DELETE_USER_ADDRESS_SUCCESS = "DELETE_USER_ADDRESS_SUCCESS",
  DELETE_USER_ADDRESS_FAILED = "DELETE_USER_ADDRESS_FAILED",
  EDIT_USER_ADDRESS_SUCCESS = "EDIT_USER_ADDRESS_SUCCESS",
  EDIT_USER_ADDRESS_FAILED = "EDIT_USER_ADDRESS_FAILED",
}

export enum HomeActionType {
  GET_LOGO_SUCCESS = "GET_LOGO_SUCCESS",
  GET_LOGO_FAILED = "GET_LOGO_FAILED",
  GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_FAILED = "GET_CATEGORIES_FAILED",
  GET_FOOTER_CATEGORIES_SUCCESS = "GET_FOOTER_CATEGORIES_SUCCESS",
  GET_FOOTER_CATEGORIES_FAILED = "GET_FOOTER_CATEGORIES_FAILED",
  GET_CONTACT_NUMBERS_SUCCESS = "GET_CONTACT_NUMBERS_SUCCESS",
  GET_CONTACT_NUMBERS_FAILED = "GET_CONTACT_NUMBERS_FAILED",
  GET_BANNERS_SUCCESS = "GET_BANNERS_SUCCESS",
  GET_BANNERS_FAILED = "GET_BANNERS_FAILED",
  GET_ABOUT_US_SUCCESS = "GET_ABOUT_US_SUCCESS",
  GET_ABOUT_US_FAILED = "GET_ABOUT_US_FAILED",
  GET_OUR_STORY_SUCCESS = "GET_OUR_STORY_SUCCESS",
  GET_OUR_STORY_FAILED = "GET_OUR_STORY_FAILED",
  GET_SINGLE_BANNER_SUCCESS = "GET_SINGLE_BANNER_SUCCESS",
  GET_SINGLE_BANNER_FAILED = "GET_SINGLE_BANNER_FAILED",
  GET_DOUBLE_BANNER_SUCCESS = "GET_DOUBLE_BANNER_SUCCESS",
  GET_DOUBLE_BANNER_FAILED = "GET_DOUBLE_BANNER_FAILED",
  GET_TRIPLE_BANNER_SUCCESS = "GET_TRIPLE_BANNER_SUCCESS",
  GET_TRIPLE_BANNER_FAILED = "GET_TRIPLE_BANNER_FAILED",
}

export enum ProductActionType {
  GET_TRENDING_PRODUCTS_SUCCESS = "GET_TRENDING_PRODUCTS_SUCCESS",
  GET_TRENDING_PRODUCTS_FAILED = "GET_TRENDING_PRODUCTS_FAILED",
  GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS",
  GET_PRODUCT_DETAILS_FAILED = "GET_PRODUCT_DETAILS_FAILED",
  GET_PRODUCT_REVIEWS_SUCCESS = "GET_PRODUCT_REVIEWS_SUCCESS",
  GET_PRODUCT_REVIEWS_FAILED = "GET_PRODUCT_REVIEWS_FAILED",
  ADD_PRODUCT_REVIEW_SUCCESS = "ADD_PRODUCT_REVIEW_SUCCESS",
  ADD_PRODUCT_REVIEW_FAILED = "ADD_PRODUCT_REVIEW_FAILED",
  GET_ALL_PRODUCT_CATEGORIES_SUCCESS = "GET_ALL_PRODUCT_CATEGORIES_SUCCESS",
  GET_ALL_PRODUCT_CATEGORIES_FAILED = "GET_ALL_PRODUCT_CATEGORIES_FAILED",
}

export enum CartActionType {
  GET_ALL_CART_PRODUCTS_SUCCESS = "GET_ALL_CART_PRODUCTS_SUCCESS",
  GET_ALL_CART_PRODUCTS_FAILED = "GET_ALL_CART_PRODUCTS_FAILED",
  ADD_PRODUCT_TO_CART_SUCCESS = "ADD_PRODUCT_TO_CART_SUCCESS",
  ADD_PRODUCT_TO_CART_FAILED = "ADD_PRODUCT_TO_CART_FAILED",
  DELETE_PRODUCT_CART_SUCCESS = "DELETE_PRODUCT_CART_SUCCESS",
  DELETE_PRODUCT_CART_FAILED = "DELETE_PRODUCT_CART_FAILED",
  UPDATE_PRODUCT_CART_SUCCESS = "UPDATE_PRODUCT_CART_SUCCESS",
  UPDATE_PRODUCT_CART_FAILED = "UPDATE_PRODUCT_CART_FAILED",
  CLEAR_CART_SUCCESS = "CLEAR_CART_SUCCESS",
  CLEAR_CART_FAILED = "CLEAR_CART_FAILED",
  REDEEM_PROMO_CODE_SUCCESS = "REDEEM_PROMO_CODE_SUCCESS",
  REDEEM_PROMO_CODE_FAILED = "REDEEM_PROMO_CODE_FAILED",
  ADD_REWARD_POINTS_SUCCESS = "ADD_REWARD_POINTS_SUCCESS",
}

export enum FavoritesActionType {
  ADD_FAVORITE_PRODUCT = "ADD_FAVORITE_PRODUCT",
  REMOVE_FAVORITE_PRODUCT = "REMOVE_FAVORITE_PRODUCT",
  CLEAR_FAVORITE = "CLEAR_FAVORITE",
}

export enum SearchActionType {
  GET_SEARCH_PRODUCTS_SUCCESS = "GET_SEARCH_PRODUCTS_SUCCESS",
  GET_SEARCH_PRODUCTS_FAILED = "GET_SEARCH_PRODUCTS_FAILED",
}
