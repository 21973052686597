import axios, { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { API } from "../../config";
import { HomeActionType } from "../action-types";

export const getLogo = () => async (dispatch: Dispatch) => {
  try {
    const response: AxiosResponse = await axios.get(`${API}/home/logo/`);
    dispatch({
      type: HomeActionType.GET_LOGO_SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (error: any) {
    dispatch({
      type: HomeActionType.GET_LOGO_FAILED,
    });
    return error?.response;
  }
};

export const getCategories = () => async (dispatch: Dispatch) => {
  try {
    const response: AxiosResponse = await axios.get(
      `${API}/product/categoryonly/`
    );
    dispatch({
      type: HomeActionType.GET_CATEGORIES_SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (error: any) {
    dispatch({
      type: HomeActionType.GET_CATEGORIES_FAILED,
    });
    return error?.response;
  }
};

export const getFooterCategories = () => async (dispatch: Dispatch) => {
  try {
    const response: AxiosResponse = await axios.get(
      `${API}/product/categoryonly/?keep_in_footer=true`
    );
    dispatch({
      type: HomeActionType.GET_FOOTER_CATEGORIES_SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (error: any) {
    dispatch({
      type: HomeActionType.GET_FOOTER_CATEGORIES_FAILED,
    });
    return error?.response;
  }
};

export const getContactNumbers = () => async (dispatch: Dispatch) => {
  try {
    const response: AxiosResponse = await axios.get(
      `${API}/home/contact-detail/`
    );
    dispatch({
      type: HomeActionType.GET_CONTACT_NUMBERS_SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (error: any) {
    dispatch({
      type: HomeActionType.GET_CONTACT_NUMBERS_FAILED,
    });
    return error?.response;
  }
};

export const getBanners = () => async (dispatch: Dispatch) => {
  try {
    const response: AxiosResponse = await axios.get(`${API}/home/banner/`);
    dispatch({
      type: HomeActionType.GET_BANNERS_SUCCESS,
      payload: response?.data?.results,
    });
    return response?.data?.results;
  } catch (error: any) {
    dispatch({
      type: HomeActionType.GET_BANNERS_FAILED,
    });
    return error.response;
  }
};

export const getAboutUs = () => async (dispatch: Dispatch) => {
  try {
    const response: AxiosResponse = await axios.get(`${API}/info/about-us/`);
    dispatch({
      type: HomeActionType.GET_ABOUT_US_SUCCESS,
      payload: response?.data,
    });
    return response?.data;
  } catch (error: any) {
    dispatch({
      type: HomeActionType.GET_ABOUT_US_FAILED,
    });
    return error.response;
  }
};

export const getOurStory = () => async (dispatch: Dispatch) => {
  try {
    const response: AxiosResponse = await axios.get(`${API}/info/our-story/`);
    dispatch({
      type: HomeActionType.GET_OUR_STORY_SUCCESS,
      payload: response?.data,
    });
    return response?.data;
  } catch (error: any) {
    dispatch({
      type: HomeActionType.GET_OUR_STORY_FAILED,
    });
    return error.response;
  }
};

export const getSingleBannerOffer = () => async (dispatch: Dispatch) => {
  try {
    const response: AxiosResponse = await axios.get(
      `${API}/home/offer?is_single=true`
    );
    dispatch({
      type: HomeActionType.GET_SINGLE_BANNER_SUCCESS,
      payload: response.data?.results,
    });
    return response;
  } catch (error: any) {
    dispatch({
      type: HomeActionType.GET_SINGLE_BANNER_FAILED,
    });
    return error.response;
  }
};

export const getDoubleBannerOffer = () => async (dispatch: Dispatch) => {
  try {
    const response: AxiosResponse = await axios.get(
      `${API}/home/offer?is_double=true`
    );
    dispatch({
      type: HomeActionType.GET_DOUBLE_BANNER_SUCCESS,
      payload: response.data?.results,
    });
    return response;
  } catch (error: any) {
    dispatch({
      type: HomeActionType.GET_DOUBLE_BANNER_FAILED,
    });
    return error.response;
  }
};

export const getTripleBannerOffer = () => async (dispatch: Dispatch) => {
  try {
    const response: AxiosResponse = await axios.get(
      `${API}/home/offer?is_triple=true`
    );
    dispatch({
      type: HomeActionType.GET_TRIPLE_BANNER_SUCCESS,
      payload: response.data?.results,
    });
    return response;
  } catch (error: any) {
    dispatch({
      type: HomeActionType.GET_TRIPLE_BANNER_FAILED,
    });
    return error.response;
  }
};
