import React, { useState } from "react";
import { useHistory } from "react-router";
import { BsHeart, BsSearch, BsThreeDotsVertical } from "react-icons/bs";
import { FaFax, FaRegUserCircle } from "react-icons/fa";
import { FiLogIn, FiLogOut, FiShoppingCart } from "react-icons/fi";
import { MdSpaceDashboard } from "react-icons/md";
import { RiWhatsappFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import millify from "millify";
import { useActions } from "../../../hooks/useAction";
import { useTypedSelector } from "../../../hooks/useSelector";
import TopUpModal from "../TopUpModal";
import { ImMobile } from "react-icons/im";

const Header: React.FC = () => {
  const [term, setTerm] = useState("");
  const [show, setShow] = useState(false);
  const { logout, getSearchProducts } = useActions();
  const { isAuthenticated, profile, reward } = useTypedSelector(
    (state) => state.users
  );
  const [topUpShow, setTopUpShow] = useState(false);
  const { contacts, logo } = useTypedSelector((state) => state.home);

  const seller = useTypedSelector((state) => state.seller);

  const { total_product_quantity } = useTypedSelector((state) => state.cart);

  const history = useHistory();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (term) {
      getSearchProducts(term, 1);
      history.push({ pathname: "/search", state: term });
    }
  };

  const handleTopUpShow = () => setTopUpShow(!topUpShow);

  return (
    <header>
      <div className="text-base bg-background">
        <div className=" max-w-6xl mx-auto md:flex justify-between px-3 py-2">
          <div className="text-green flex items-center justify-between">
            {contacts.phone && (
              <div className="flex">
                <RiWhatsappFill size={20} />
                <span className="ml-2 text-sm font-semibold">
                  {contacts.phone}
                </span>
              </div>
            )}
            {contacts.fax && (
              <div className="flex ml-5">
                <FaFax size={16} />
                <span className="ml-2 text-sm font-semibold">
                  {contacts.fax}
                </span>
              </div>
            )}
            <div className="ml-5 md:hidden relative text-gray-700">
              <div onClick={() => setShow(!show)}>
                <BsThreeDotsVertical />
              </div>
              {show && (
                <div className="absolute top-8 right-0 bg-background shadow-2xl w-40 p-5 z-50 rounded-md">
                  <div className="absolute -top-1 right-1 border-t-8 border-r-8 w-4 h-4 border-background transform -rotate-45 z-30"></div>
                  {isAuthenticated && (
                    <div className="flex items-center justify-center my-2">
                      <div
                        className="py-2 px-2 bg-red flex justify-center items-center text-white rounded-full text-base font-semibold"
                        style={{ minWidth: 40, minHeight: 40 }}
                      >
                        {reward.reward_point ? reward.reward_point : 0}
                      </div>
                    </div>
                  )}
                  <Link to="/wishlist">
                    <div className="flex items-center my-2">
                      <div>
                        <BsHeart size={20} />
                      </div>
                      <span className="ml-3">Wishlist</span>
                    </div>
                  </Link>
                  {isAuthenticated && (
                    <div
                      className="flex items-center mr-2 hover:text-red duration-200 cursor-pointer"
                      onClick={() => {
                        setShow(!show);
                        handleTopUpShow();
                      }}
                    >
                      <ImMobile /> <span className="ml-2">Top Up</span>
                    </div>
                  )}
                  {!isAuthenticated && (
                    <Link to="/login">
                      <div className="flex items-center my-3 hover:text-red duration-200">
                        <div>
                          <FiLogIn size={20} />
                        </div>
                        <span className="ml-3" onClick={() => setShow(!show)}>
                          Login
                        </span>
                      </div>
                    </Link>
                  )}
                  {profile?.is_seller && seller.is_verified && (
                    <Link to="/seller/dashboard">
                      <div className="flex items-center my-3 hover:text-red duration-200">
                        <div>
                          <MdSpaceDashboard size={20} />
                        </div>
                        <span className="ml-3" onClick={() => setShow(!show)}>
                          Dashboard
                        </span>
                      </div>
                    </Link>
                  )}
                  {isAuthenticated && (
                    <button
                      className="flex cursor-pointer items-center my-3  hover:text-red duration-200"
                      onClick={() => logout()}
                    >
                      <div>
                        <FiLogOut size={20} />
                      </div>
                      <span className="ml-3" onClick={() => setShow(!show)}>
                        Logout
                      </span>
                    </button>
                  )}
                  {!profile?.is_seller && (
                    <Link to="/apply-seller">
                      <div className="mr-3" onClick={() => setShow(!show)}>
                        Apply for seller
                      </div>
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="hidden md:flex items-center">
            {isAuthenticated && (
              <div
                className="flex items-center mr-2 hover:text-red duration-200 cursor-pointer"
                onClick={() => handleTopUpShow()}
              >
                <ImMobile /> <span className="ml-2">Top Up</span>
              </div>
            )}
            {!profile?.is_seller && (
              <Link to="/apply-seller">
                <div className="ml-3">Apply for seller</div>
              </Link>
            )}
            {profile?.is_seller && seller.is_verified && (
              <Link to="/seller/dashboard">
                <div className="flex items-center ml-2 hover:text-red duration-200">
                  <MdSpaceDashboard /> <span className="ml-2">Dashboard</span>
                </div>
              </Link>
            )}
            {!isAuthenticated && (
              <Link to="/login">
                <div className="flex items-center ml-2 hover:text-red duration-200">
                  <FiLogIn /> <span className="ml-2">Login or Register</span>
                </div>
              </Link>
            )}
            {isAuthenticated && (
              <Link to="/account/profile">
                <div className="flex items-center ml-2 hover:text-red duration-200">
                  <FaRegUserCircle />{" "}
                  <span className="ml-2">
                    {profile && profile.full_name.split(" ")[0]}
                  </span>
                </div>
              </Link>
            )}
            {isAuthenticated && (
              <div
                className="flex cursor-pointer items-center ml-2 hover:text-red duration-200"
                onClick={() => logout()}
              >
                <FiLogOut /> <span className="ml-2">Logout</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <TopUpModal show={topUpShow} handleTopUpShow={handleTopUpShow} />
      <div className="max-w-6xl mx-auto px-2 md:px-5 flex justify-between items-center my-3 text-base sm:text-lg">
        <div className="block">
          <Link to="/">
            <img
              src={logo}
              alt="Aanbhi"
              title="Aanbhi"
              className="w-44"
              style={{ minWidth: 150 }}
            />
          </Link>
        </div>

        <form
          className="border-2 border-gray-300 rounded outline-none flex items-center py-2 px-3 text-gray-600 w-4/5 md:w-2/5 ml-2"
          onSubmit={handleSubmit}
        >
          <input
            type="text"
            placeholder="Search by Products, Category or Collection"
            className="outline-none w-full text-base pr-2"
            onChange={(e) => setTerm(e.target.value)}
          />
          <button type="submit">
            <BsSearch />
          </button>
        </form>
        <div className="flex items-center">
          {isAuthenticated && (
            <div className="hidden md:flex items-center mx-3">
              <div
                className="py-2 px-2 bg-red flex justify-center items-center text-white rounded-full text-sm font-semibold"
                style={{ minWidth: 40, minHeight: 40 }}
              >
                {reward.reward_point
                  ? millify(parseInt(reward.reward_point))
                  : 0}
              </div>
              <span className="ml-2">Rewards</span>
            </div>
          )}

          <Link to="/wishlist" className="hover:text-red duration-200">
            <div className="hidden md:flex items-center mx-3 hover:text-red duration-200">
              <div>
                <BsHeart size={20} />
              </div>
              <span className="ml-2">Wishlist</span>
            </div>
          </Link>
          <div className="hidden md:flex">
            <Link to="/cart" className="hover:text-red duration-200 relative">
              <FiShoppingCart size={20} />
              {isAuthenticated && (
                <span
                  className="absolute -top-5 -right-4 rounded-full flex justify-center items-center p-2 h-6 text-white bg-red font-semibold"
                  style={{ fontSize: 10 }}
                >
                  {total_product_quantity}
                </span>
              )}
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
