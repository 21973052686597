import { HomeActionType } from "../action-types";
import { HomeAction } from "../actions/home";
import AanbhiLogo from "../../assets/aanbhi-logo.png";

interface HomeInitialState {
  logo: string | any;
  categories: Categories | null;
  footerCategories: Categories | null;
  contacts: ContactNumbers;
  banners: Banners[];
  singleBannerOffers: BannerOffer[];
  doubleBannerOffers: BannerOffer[];
  tripleBannerOffers: BannerOffer[];
  aboutUs: AboutUs | null;
  ourStory: OurStory | null;
}

const initialState: HomeInitialState = {
  logo: AanbhiLogo,
  categories: null,
  footerCategories: null,
  contacts: {
    fax: "",
    phone: "",
  },
  banners: [],
  singleBannerOffers: [],
  doubleBannerOffers: [],
  tripleBannerOffers: [],
  aboutUs: null,
  ourStory: null,
};

const homeReducer = (state = initialState, action: HomeAction) => {
  switch (action.type) {
    case HomeActionType.GET_LOGO_SUCCESS:
      return {
        ...state,
        logo: action.payload?.logo,
      };
    case HomeActionType.GET_LOGO_FAILED:
      return {
        ...state,
        logo: AanbhiLogo,
      };
    case HomeActionType.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
      };
    case HomeActionType.GET_CATEGORIES_FAILED:
      return {
        ...state,
        categories: null,
      };
    case HomeActionType.GET_FOOTER_CATEGORIES_SUCCESS:
      return {
        ...state,
        footerCategories: action.payload,
      };
    case HomeActionType.GET_FOOTER_CATEGORIES_FAILED:
      return {
        ...state,
        footerCategories: null,
      };
    case HomeActionType.GET_CONTACT_NUMBERS_SUCCESS:
      return {
        ...state,
        contacts: {
          fax: action.payload?.fax,
          phone: action.payload?.phone,
        },
      };
    case HomeActionType.GET_CONTACT_NUMBERS_FAILED:
      return {
        ...state,
        contacts: {
          fax: "",
          phone: "",
        },
      };
    case HomeActionType.GET_BANNERS_SUCCESS:
      return {
        ...state,
        banners: action.payload,
      };
    case HomeActionType.GET_BANNERS_FAILED:
      return {
        ...state,
        banners: [],
      };
    case HomeActionType.GET_SINGLE_BANNER_SUCCESS:
      return {
        ...state,
        singleBannerOffers: action.payload,
      };
    case HomeActionType.GET_SINGLE_BANNER_FAILED:
      return {
        ...state,
        singleBannerOffers: [],
      };
    case HomeActionType.GET_DOUBLE_BANNER_SUCCESS:
      return {
        ...state,
        doubleBannerOffers: action.payload,
      };
    case HomeActionType.GET_DOUBLE_BANNER_FAILED:
      return {
        ...state,
        doubleBannerOffers: [],
      };
    case HomeActionType.GET_TRIPLE_BANNER_SUCCESS:
      return {
        ...state,
        tripleBannerOffers: action.payload,
      };
    case HomeActionType.GET_TRIPLE_BANNER_FAILED:
      return {
        ...state,
        tripleBannerOffers: [],
      };
    case HomeActionType.GET_ABOUT_US_SUCCESS:
      return {
        ...state,
        aboutUs: action.payload,
      };
    case HomeActionType.GET_ABOUT_US_FAILED:
      return {
        ...state,
        aboutUs: null,
      };
    case HomeActionType.GET_OUR_STORY_SUCCESS:
      return {
        ...state,
        ourStory: action.payload,
      };
    case HomeActionType.GET_OUR_STORY_FAILED:
      return {
        ...state,
        ourStory: null,
      };
    default:
      return state;
  }
};

export default homeReducer;
